const React = require('react');
const { number, string, shape } = require('prop-types');
const { Image } = require('nordic/image');
const { trackEvent } = require('../../lib/tracking');

const namespace = 'ui-pdp__header-brand-logo';

const HeaderBrandLogo = ({ image, link, track }) => {
  const containerStyle = React.useMemo(() => {
    const styles = {};
    if (image.width && image.height) {
      styles.aspectRatio = `${image.width} / ${image.height}`;
    }
    return styles;
  }, [image.width, image.height]);

  return (
    <a href={link} onClick={() => trackEvent(track)}>
      <div className={namespace} style={{ ...containerStyle }}>
        <Image alt="" className={`${namespace}__image`} src={image.url.src} />
      </div>
    </a>
  );
};

HeaderBrandLogo.propTypes = {
  image: shape({
    url: shape({
      src: string.isRequired,
    }).isRequired,
    height: number,
    width: number,
  }).isRequired,
  link: string.isRequired,
  track: shape({}),
};

HeaderBrandLogo.defaultProps = {
  track: null,
};

module.exports = HeaderBrandLogo;
