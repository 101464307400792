import withRow from '../../../../components/grid/row/with-row';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';

const FEATURE_NAME = 'highlighted_specs_features';

const mapStateToProps = ({ components: { [FEATURE_NAME]: highlightedSpecs }, id }) => ({
  ...highlightedSpecs,
  productId: id,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const highlightedSpecsFeatures = HighlightedSpecsFeatures => enhance(HighlightedSpecsFeatures);

export default highlightedSpecsFeatures;
