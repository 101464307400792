const React = require('react');
const { string, shape } = require('prop-types');
const usePostClose = require('../../hooks/use-post-close');
const IFrameReviews = require('../../../iframe-reviews');
const { forEach } = require('../../../../lib/dom-utils');
const MediaAction = require('../../../media/media-action');
const HistoryService = require('../../../../services/history');
const { runSchedulerTask } = require('../../../../utils/validators');

const namespace = 'ui-pdp-reviews__actions';

const ReviewActions = ({ allReviews, newReview, title }) => {
  const pipe = '|';
  const [reviewsVisible, setReviewsVisible] = usePostClose(false);
  const onClickHandler = e => {
    e.preventDefault();
    setReviewsVisible(true);

    // Se calculan en base al react-remove-scroll-bar y cumplen la función de suplantar el espacio
    // dejado por la scrollbar en los casos que se utilice mouse
    // @TODO: Integrate them into iframereviews
    const main = document.querySelectorAll('body > header, body > main') || [];

    forEach(main, element => {
      element.classList.add('with-scroll-bars-hidden');
    });
  };

  const onCloseHandler = e => {
    if (e) {
      e.preventDefault();
    }
    setReviewsVisible(false);

    // Se calculan en base al react-remove-scroll-bar y cumplen la función de suplantar el espacio
    // dejado por la scrollbar en los casos que se utilice mouse
    const main = document.querySelectorAll('body > header, body > main') || [];

    forEach(main, element => {
      element.classList.remove('with-scroll-bars-hidden');
    });

    runSchedulerTask(() => {
      const historyService = HistoryService;
      historyService.back();
    }, 'background');
  };

  return (
    <div className={`${namespace}__container`}>
      {allReviews && (
        <>
          <a href={allReviews.target} onClick={onClickHandler} className={`${namespace}__button ui-pdp-media__action`}>
            {allReviews.label.text}
          </a>
          <IFrameReviews
            visible={reviewsVisible}
            action={allReviews}
            title={title}
            hashId="reviews"
            onClose={onCloseHandler}
          />
        </>
      )}
      {allReviews && newReview && <span className={`${namespace}__divider`}>{pipe}</span>}
      {newReview && <MediaAction className={`${namespace}__new-review`} {...newReview} />}
    </div>
  );
};

ReviewActions.propTypes = {
  allReviews: shape({
    type: string,
    target: string,
    label: shape({
      text: string,
      color: string,
    }),
  }),
  newReview: shape({
    type: string,
    target: string,
    label: shape({
      text: string,
      color: string,
    }),
  }),
  title: string,
};

ReviewActions.defaultProps = {
  allReviews: null,
  newReview: null,
  title: null,
};

module.exports = ReviewActions;
