const React = require('react');
const { string } = require('prop-types');
const Markup = require('./markup');
const StaticPropsContext = require('../context/static-props');

const { useContext } = React;
const namespace = 'ui-pdp-recaptcha-v3';

const onLoadRecaptchav3Token = (siteKey, action, callBack, fallback) => {
  const { grecaptcha } = window;
  if (grecaptcha && grecaptcha.enterprise) {
    grecaptcha.enterprise.ready(() => {
      grecaptcha.enterprise
        .execute(siteKey, { action })
        .then(recaptchaTokenv3 => callBack(recaptchaTokenv3))
        .catch(e => fallback(e));
    });
  }
};

const Recaptchav3 = ({ siteKey }) => {
  const { country } = useContext(StaticPropsContext);
  const recaptchaLang = country.id === 'BR' || country.id === 'PT' ? 'pt-BR' : 'es-419';

  return (
    <div className={namespace}>
      <Markup siteKey={siteKey} lang={recaptchaLang} />
    </div>
  );
};

Recaptchav3.propTypes = {
  siteKey: string,
};

Recaptchav3.defaultProps = {
  siteKey: null,
};

module.exports = { Recaptchav3, onLoadRecaptchav3Token };
