import compose from '../../../../../lib/compose';
import connect from '../../../../../store/connect';
import withFeatureFlag from '../../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../../components/grid/row/with-row';
import jsonToProps from '../generic-summary/pickup-mapper';

const FEATURE_NAME = 'returns_summary';

const RETURNS_PROPS = {
  id: FEATURE_NAME,
};

const mapStateToProps = ({ components: { [FEATURE_NAME]: returnsSummary } }, ownProps) =>
  jsonToProps({ ...returnsSummary, ...ownProps, ...RETURNS_PROPS });

const serializeOwnProps = (state, ownProps) => jsonToProps({ ...ownProps, ...RETURNS_PROPS });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const noFeatureFlagConnect = connect(serializeOwnProps);
const pickUpSummary = PickUpSummary => enhance(PickUpSummary);
const noFeatureFlag = PickUpSummary => noFeatureFlagConnect(PickUpSummary);

export default pickUpSummary;
export { noFeatureFlag };
