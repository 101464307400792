const React = require('react');
const { string } = require('prop-types');
const Script = require('nordic/script');

const isReady = () =>
  typeof window !== 'undefined' &&
  typeof window.grecaptcha !== 'undefined' &&
  typeof window.grecaptcha.enterprise !== 'undefined';

const Markup = ({ lang, siteKey }) =>
  !isReady() && (
    <Script>{`
        var recaptchaScript = document.createElement('script');
        recaptchaScript.src = 'https://www.google.com/recaptcha/enterprise.js?hl=${lang}&render=${siteKey}';
        document.head.appendChild(recaptchaScript);
      `}</Script>
  );

Markup.propTypes = {
  siteKey: string,
  lang: string,
};

Markup.defaultProps = {
  siteKey: null,
  lang: 'es-419',
};

module.exports = Markup;
