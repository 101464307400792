const React = require('react');
const classnames = require('classnames');
const { arrayOf, string, shape, number } = require('prop-types');
const { ActionCollapsable } = require('../action-collapsable');
const Image = require('../image');
const useTrackView = require('../../hooks/use-track-view');

const namespace = 'pdp-enhanced-descriptions';

const EnhancedDescriptions = ({ title, pictures, viewPortTrack, action, maxShowPixels }) => {
  const viewRef = useTrackView(viewPortTrack, { threshold: 0 });
  const showTitle = title.text && title.text.length > 0;

  return (
    <div className={`${namespace}__content`} ref={viewRef}>
      {showTitle && (
        <h2 data-testid="title" className={`${namespace}__title`}>
          {title.text}
        </h2>
      )}
      <ActionCollapsable
        action={action}
        scrollToTop={false}
        maxCollapsed={maxShowPixels}
        collapseBy="pixels"
        className={classnames(namespace)}
        gradientType="small"
      >
        <div className={`${namespace}__pictures`}>
          {pictures.map(picture => (
            <div key={picture?.id} className={`${namespace}__image-wrapper`}>
              <Image alt={picture?.alt} {...picture} className={`${namespace}__image`} />
            </div>
          ))}
        </div>
      </ActionCollapsable>
    </div>
  );
};

EnhancedDescriptions.propTypes = {
  viewPortTrack: shape({}),
  action: shape({
    target: string,
    label: shape({
      text: string.isRequired,
      color: string.isRequired,
    }).isRequired,
  }).isRequired,
  title: shape({
    text: string.isRequired,
    color: string.isRequired,
  }).isRequired,
  pictures: arrayOf(
    shape({
      id: string.isRequired,
      alt: string.isRequired,
      src: string.isRequired,
      src2x: string,
    }),
  ).isRequired,
  maxShowPixels: number.isRequired,
};

EnhancedDescriptions.defaultProps = {
  open_image_track: null,
  viewPortTrack: null,
};

module.exports = EnhancedDescriptions;
