const React = require('react');
const { shape, func, string } = require('prop-types');
const classnames = require('classnames');

const object = require('../../lib/global');

const ActionModal = require('../action-modal');
const Iframe = require('../iframe');
const transformActionTarget = require('../../utils/transformActionTarget');

const getAction = (namespace, action, onIframeMessage, onCouponUpdate, actionModalRef, actionModalProps) => {
  const lowEndUrl = { ...action };
  transformActionTarget(lowEndUrl, { modal: false });

  let couponModalChanged = false;

  function messageHandler(event) {
    if (event?.data?.event_from === 'coupon-modal' && event?.data?.type === 'coupons_actions') {
      couponModalChanged = true;
      object.removeEventListener('message', messageHandler);
    }
  }

  const onCloseModal = () => {
    if (couponModalChanged && onCouponUpdate) {
      couponModalChanged = false;
      object.removeEventListener('message', messageHandler);
      onCouponUpdate();
    }
  };

  const onOpenModal = () => {
    if (object?.addEventListener) {
      object.addEventListener('message', messageHandler);
    }
  };

  return (
    <ActionModal
      label={action.label}
      modalTitle={action.modal_title}
      modalClassName={classnames(
        `${namespace}-modal ${action.modal_title ? null : 'modal-close-button-mobile'}`,
        'ui-pdp-iframe-modal',
        actionModalProps?.className,
      )}
      className={`${namespace}__action`}
      track={action.track}
      closeTrack={action.close_track}
      url={lowEndUrl.target}
      closeModalLabel={action.close_modal_label}
      controlModalHeader
      showCloseButton
      onCloseModal={onCloseModal}
      onOpenModal={onOpenModal}
      customRef={actionModalRef}
      {...actionModalProps}
    >
      <Iframe
        title={action.label ? action.label.text : null}
        src={action.target}
        onMessage={onIframeMessage}
        scrolling="no"
        width="100%"
      />
    </ActionModal>
  );
};

getAction.propTypes = {
  namespace: string,
  onIframeMessage: func,
  onCouponUpdate: func.isRequired,
  action: shape({}),
};

getAction.defaultProps = {
  namespace: '',
  onIframeMessage: null,
  onCouponUpdate: null,
  action: null,
};

module.exports = getAction;
