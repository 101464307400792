const React = require('react');
const { string } = require('prop-types');
const Image = require('nordic/image');

const ICON_ID = 'empty_reviews';

const IconEmptyReviews = ({ className }) => (
  <div className={className}>
    <Image src="empty-reviews.svg" alt="" />
  </div>
);

IconEmptyReviews.propTypes = {
  className: string,
};

IconEmptyReviews.defaultProps = {
  className: '',
};

module.exports = React.memo(IconEmptyReviews);
exports = module.exports;
exports.ICON_ID = ICON_ID;
