import compose from '../../../../../lib/compose';
import connect from '../../../../../store/connect';
import withFeatureFlag from '../../../../../components/feature-flag/with-feature-flag';
import actions from '../../../../../actions';

const FEATURE_NAME = 'cart_actions';

const mapStateToProps = ({ components: { [FEATURE_NAME]: cartActions } }) => cartActions;

const mapDispatchToProps = dispatch => ({
  onUpdateQuantity: ({ action, quantity, target, callback, onSuccess, onError }) =>
    dispatch(actions.addToCartUpdate({ action, quantity, target, callback, onSuccess, onError })),
  showVariationsError: deviceType => dispatch(actions.showVariationsError(deviceType)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const cartActions = CartActions => enhance(CartActions);

export default cartActions;
