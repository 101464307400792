const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const withRow = require('../../../../components/grid/row/with-row');
const actions = require('../../../../actions');
const PricePerQuantityShortcuts = require('../../../../components/price-per-quantity-shortcuts/price-per-quantity-shortcuts');

const FEATURE_NAME = 'price_per_quantity_shortcuts';

const mapStateToProps = ({ components: { [FEATURE_NAME]: price_per_quantity_shortcuts } }, { className }) => ({
  ...price_per_quantity_shortcuts,
  className,
});

const mapDispatchToProps = dispatch => ({
  onSetQuantity: quantity => dispatch(actions.fetchOnSetQuantity(quantity)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

module.exports = enhance(PricePerQuantityShortcuts);
