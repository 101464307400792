import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import mapStateToProps from '../../../../components/recommendations/state-mapper-base';
import RecommendationsCarouselFreeHorizontalNeighbour from '../../../../components/recommendations/carousel-snapped-horizontal-neighbour';

const enhance = compose(
  withFeatureFlag(),
  connect(mapStateToProps()),
);

export default enhance(RecommendationsCarouselFreeHorizontalNeighbour);
