const React = require('react');
const { string, node, shape } = require('prop-types');
const backgroundColor = require('../../lib/background-color');

const StyledContainer = ({ children, bgColor, className }) => (
  <div className={backgroundColor(bgColor, className)}>{children}</div>
);

StyledContainer.propTypes = {
  bgColor: shape({ text: string.isRequired }).isRequired,
  children: node.isRequired,
  className: string.isRequired,
};

module.exports = StyledContainer;
