const jsonToProps = ({
  title,
  rating,
  stars,
  subtitle,
  rating_levels,
  all_reviews,
  tabs,
  id,
  empty_reviews_subtitle,
  ...rest
}) => {
  if (!title && !rating && !subtitle && !rating_levels && !all_reviews && !tabs && !id) {
    return null;
  }

  return {
    key: id,
    title,
    rating: {
      average: rating,
      label: subtitle,
      levels: rating_levels,
      stars,
    },
    comments: {
      tabs,
      label: all_reviews && all_reviews.label,
    },
    emptySubtitle: { ...empty_reviews_subtitle },
    ...rest,
  };
};

module.exports = jsonToProps;
