import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const FEATURE_NAME = 'stock_information';

const mapStateToProps = (
  {
    components: {
      [FEATURE_NAME]: { collapsible_tooltip, title, subtitle },
    },
  },
  ownProps,
) => ({
  collapsible_tooltip,
  title,
  subtitle,
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

const stockInformation = StockInformation => enhance(StockInformation);

export default stockInformation;
