import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import Features from '../../../../components/features';

const FEATURE_NAME = 'main_features';

const mapStateToProps = ({ components: { [FEATURE_NAME]: mainFeatures } }, { className }) => ({
  features: mainFeatures.main_features,
  className,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(Features);
