const React = require('react');
const { useContext } = require('react');
const { bool, func, number, shape, string, arrayOf, oneOf } = require('prop-types');

const StaticPropsContext = require('../../context/static-props');
const QuantitySelectorDesktop = require('./quantity-selector.desktop');
const QuantityNative = require('./quantity-native');
const QuantityTrigger = require('./quantity-trigger');
const StyledLabel = require('../../styled-label');
const Quantity = require('./quantity');

const QuantityDesktop = ({
  className,
  quantity_selector,
  picker,
  showInput,
  label,
  type,
  showQuantityInput,
  showInputLowend,
  onSetQuantity,
  onShowInput,
  buttonFocus,
}) => {
  const yieldValue = quantity_selector?.selector?.template?.yield;
  const bulkSale = !!yieldValue;
  const { lowEnd } = useContext(StaticPropsContext);
  if (lowEnd && !showInputLowend) {
    // @TODO check diff between PDS & PDP until UX has a better alternative
    return null;
  }

  if (type === 'label_component') {
    return (
      <Quantity className={className} picker={picker}>
        <StyledLabel {...label} />
      </Quantity>
    );
  }

  if (picker) {
    if (lowEnd) {
      return <QuantityNative message={picker.description} quantity={picker.selected} />;
    }

    return (
      <Quantity className={className} picker={picker}>
        {quantity_selector ? (
          <QuantitySelectorDesktop
            message={picker.description}
            setQuantity={onSetQuantity}
            showInput={showQuantityInput || showInput}
            onShowInput={onShowInput}
            picker={picker}
            quantitySelector={quantity_selector}
            buttonFocus={buttonFocus}
          />
        ) : (
          <QuantityTrigger
            title={picker.title}
            selectedLabel={picker.selected_label && picker.selected_label.text}
            message={picker.description}
            active={false}
            bulkSale={bulkSale}
            disabled
          />
        )}
      </Quantity>
    );
  }

  return null;
};

QuantityDesktop.propTypes = {
  className: string,
  picker: shape({
    title: string,
    selected_label: shape({
      text: string,
      color: string,
    }),
    description: string,
    messages: arrayOf(
      shape({
        text: string,
        color: string,
        font_size: string,
        font_family: string,
      }),
    ),
    track: shape({}),
  }).isRequired,
  type: oneOf(['label_component', 'available_quantity']).isRequired,
  label: shape({
    text: string.isRequired,
    font_family: string.isRequired,
    font_size: string.isRequired,
    color: string.isRequired,
  }),
  quantity_selector: shape({
    available_quantity: number,
    selector: shape({
      title: shape({
        text: string,
        color: string,
      }),
      template: shape({
        singular: string,
        plural: string,
        more: string,
        volume_discount_options: arrayOf(
          shape({
            quantity: shape({
              text: string,
            }),
            amount: shape({
              fraction: string,
              cents: string,
              suffix: shape({
                text: string,
              }),
            }),
          }),
        ),
      }).isRequired,
      rows: number,
    }).isRequired,
    input: shape({
      title: shape({
        text: string,
        color: string,
      }),
      error_message: shape({
        text: string,
        color: string,
      }),
    }).isRequired,
  }),
  showQuantityInput: bool,
  showInput: bool,
  showInputLowend: bool,
  onSetQuantity: func.isRequired,
  onShowInput: func.isRequired,
  buttonFocus: bool,
};

QuantityDesktop.defaultProps = {
  className: null,
  showQuantityInput: false,
  showInput: false,
  showInputLowend: true,
  label: null,
  quantity_selector: null,
  buttonFocus: false,
};

module.exports = QuantityDesktop;
