const React = require('react');
const { oneOfType, number, string } = require('prop-types');
const StarFullIcon = require('../icons/star-full');
const StarHalfIcon = require('../icons/star-half');
const StarEmptyIcon = require('../icons/star-empty');

const MAX_STARS = 5;

const Stars = ({ value, className }) => {
  const fullStars = Math.floor(value);
  const halfStars = Math.ceil(value - fullStars);
  const emptyStars = MAX_STARS - halfStars - fullStars;

  const ratings = [];
  let key = 0;
  for (let f = 0; f < fullStars; f += 1) {
    ratings.push(<StarFullIcon className={`${className}__star`} key={(key += 1)} />);
  }

  if (halfStars) {
    ratings.push(<StarHalfIcon className={`${className}__star`} key={(key += 1)} />);
  }

  for (let e = 0; e < emptyStars; e += 1) {
    ratings.push(<StarEmptyIcon className={`${className}__star`} key={(key += 1)} />);
  }

  return <div className={className}>{ratings}</div>;
};

Stars.propTypes = {
  className: string,
  value: oneOfType([string, number]),
};

Stars.defaultProps = {
  className: '',
  value: 0,
};

module.exports = React.memo(Stars);
