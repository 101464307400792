import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';

const FEATURE_NAME = 'cpg_summary';

const CPG_PROPS = {
  id: FEATURE_NAME,
};

const mapStateToProps = ({ components: { [FEATURE_NAME]: cpgSummary }, isFetching }, ownProps) => ({
  ...cpgSummary,
  ...ownProps,
  ...CPG_PROPS,
  isFetching,
});

const serializeOwnProps = ({ isFetching }, ownProps) => ({
  ...ownProps,
  ...CPG_PROPS,
  isFetching,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME, 'mb-20'),
  connect(mapStateToProps),
);

const noFeatureFlagConnect = connect(serializeOwnProps);
const cpgSummary = CpgSummary => enhance(CpgSummary);
const noFeatureFlag = CpgSummary => noFeatureFlagConnect(CpgSummary);

export default cpgSummary;
export { noFeatureFlag };
