import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import jsonToProps from '../../../../components/related-searches/related-searches-mapper';

const RelatedSearches = loadable(() => import('../../../../components/related-searches'));

const FEATURE_NAME = 'related_searches';

const mapStateToProps = ({
  components: {
    [FEATURE_NAME]: { title, related_searches },
  },
}) => jsonToProps({ title, related_searches });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { loader: false }),
  connect(mapStateToProps),
);

export default enhance(RelatedSearches);
