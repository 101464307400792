const React = require('react');
const { shape, string } = require('prop-types');
const Message = require('../message');
const StyledLabel = require('../styled-label');
const componentEnhance = require('../../lib/component-enhance');

const namespace = 'up-vip-fraud-prevention';

const FraudMessage = ({ hierarchy, message_type, body }) => (
  <Message className={`${namespace}__message-container`} hierarchy={hierarchy} type={message_type}>
    <div className={`${namespace}__message-text`}>
      <StyledLabel as="span" text={body && componentEnhance.jsx(body.text || body, body.values)} />
    </div>
  </Message>
);

FraudMessage.propTypes = {
  hierarchy: string.isRequired,
  message_type: string.isRequired,
  body: shape({ text: string, values: shape() }).isRequired,
};

module.exports = FraudMessage;
