const React = require('react');
const { shape, string } = require('prop-types');
const classnames = require('classnames');
const colornames = require('../../lib/colornames');
const componentEnhance = require('../../lib/component-enhance');
const StyledLabel = require('../styled-label');
const IconFactory = require('../icons/factory-builder')();
const Message = require('../message');
const Media = require('../media');

const QuestionsMessageLocation = ({ icon, hierarchy, message_type, title, type }) => {
  const namespace = 'ui-pdp-questions';
  return (
    <div>
      {type === 'SIMPLE_TEXT' && (
        <Media
          className={classnames(`${namespace}__message-location--simple`, colornames(icon))}
          title={title}
          figure={IconFactory(icon)}
        />
      )}
      {type === 'UI_MESSAGE' && (
        <Message className={`${namespace}__message-location--container`} hierarchy={hierarchy} type={message_type}>
          <div className={`${namespace}__message-location__text`}>
            <StyledLabel as="span" text={title && componentEnhance.jsx(title.text || title, title.values)} />
          </div>
        </Message>
      )}
    </div>
  );
};

QuestionsMessageLocation.propTypes = {
  hierarchy: string,
  icon: shape({ id: string, color: string }),
  message_type: string,
  title: shape(),
  type: string,
};

QuestionsMessageLocation.defaultProps = {
  hierarchy: null,
  icon: null,
  message_type: null,
  title: null,
  type: null,
};

module.exports = QuestionsMessageLocation;
