const React = require('react');
const { useContext, useState, useMemo } = require('react');
const { string, shape } = require('prop-types');
const StyledLabel = require('../../../styled-label');
const StaticPropsContext = require('../../../context/static-props');
const componentEnhance = require('../../../../lib/component-enhance');
const messageSeeMore = require('../../../../lib/get-see-more-message');
const addEllipsisEnd = require('../../../../utils/addEllipsisEnd');
const maxCharsDevice = require('../../../../utils/max-chars-device');

const namespace = `ui-vpp-questions-ai-suggestion-answer`;

const QuestionsAiTextCollapse = ({ answer }) => {
  const { siteId, deviceType } = useContext(StaticPropsContext);
  const MAX_CHARS = maxCharsDevice(deviceType, 'questions-ai');

  const getPlainText = (baseText, targets) => {
    let plainText = baseText;

    if (targets) {
      Object.values(targets).forEach(target => {
        plainText += target.label.text;
      });
    }

    return plainText;
  };

  const [isCollapsed, setCollapsedState] = useState(() => {
    const plainText = getPlainText(answer.text, answer.targets);
    return plainText.length > MAX_CHARS;
  });

  const textToShow = useMemo(() => {
    let displayText = answer.text;

    if (isCollapsed) {
      const plainText = getPlainText(answer.text, answer.targets);

      if (plainText.length > MAX_CHARS) {
        const truncatedText = answer.text.substring(0, MAX_CHARS);
        const lastSpaceIndex = truncatedText.lastIndexOf(' ');
        displayText = addEllipsisEnd(
          lastSpaceIndex !== -1 ? truncatedText.substring(0, lastSpaceIndex) : truncatedText,
        );
      }
    }

    return displayText;
  }, [MAX_CHARS, isCollapsed, answer.text, answer.targets]);

  return (
    <div className={namespace}>
      <StyledLabel
        className={`${namespace}__text ${namespace}__text--bot-response`}
        as="span"
        color={answer.color}
        font_size={answer.font_size}
        font_family={answer.font_family}
        text={componentEnhance.jsx(textToShow, answer.targets)}
      />
      {isCollapsed && (
        <StyledLabel
          as="a"
          color="BLUE"
          font_size="XSMALL"
          font_family="REGULAR"
          className={`${namespace}__see-more-button`}
          text={messageSeeMore(siteId)}
          onClick={e => {
            e.preventDefault();
            setCollapsedState(false);
          }}
        />
      )}
    </div>
  );
};

QuestionsAiTextCollapse.propTypes = {
  answer: shape({
    text: string.isRequired,
    color: string.isRequired,
    font_size: string.isRequired,
    font_family: string.isRequired,
  }).isRequired,
};

QuestionsAiTextCollapse.defaultProps = {
  osName: null,
};

module.exports = QuestionsAiTextCollapse;
