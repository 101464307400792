const React = require('react');
const { number, bool, string, shape, arrayOf } = require('prop-types');
const { ActionCollapsable } = require('../../../action-collapsable');
const HSStripedSpecs = require('../hs-striped-specs');

const namespace = 'ui-vpp-highlighted-specs__striped-collapsed';

const HSActionCollapsible = ({ action, specs, maxCollapsed, showAction, collapse }) => {
  if (!action) {
    return null;
  }

  const updateSpecsHeight = () => {
    const stickyWrapper = document.querySelector('.ui-pdp--sticky-wrapper-center');
    if (stickyWrapper) {
      stickyWrapper.style.top = `10px`;
      stickyWrapper.style.marginTop = '0px';
    }
  };

  return (
    <ActionCollapsable
      className={`${namespace}__action`}
      collapseBy="pixels"
      maxCollapsed={maxCollapsed}
      action={action}
      collapse={collapse}
      scrollToTop={false}
      onClickAction={updateSpecsHeight}
      showAction={showAction}
      allowRecollapse={!!collapse}
      useCanSeeEverything
    >
      <HSStripedSpecs specs={specs} />
    </ActionCollapsable>
  );
};

HSActionCollapsible.propTypes = {
  specs: arrayOf(shape({ column: string })).isRequired,
  action: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string,
  }),
  collapse: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string,
  }),
  maxCollapsed: number,
  showAction: bool,
};

HSActionCollapsible.defaultProps = {
  action: null,
  showAction: true,
  maxCollapsed: 1,
};

module.exports = HSActionCollapsible;
