const {
  INSTALATION_ID,
  INSTALATION_WIDGET_ID,
  INSTALLATION_SERVICE,
} = require('../../components/installation-service/installation.constants');
const { trackEvent } = require('../../lib/tracking');
const { SHOW_SNACKBAR } = require('../actions');

const getInstallationWidget = payload => {
  const components = payload.components.short_description ?? payload.components;
  let installation;
  if (!Array.isArray(components)) {
    installation = components.installations;
  } else {
    installation = components?.find(component => component.id === INSTALATION_ID);
  }
  return installation.components?.find(c => c.id === INSTALATION_WIDGET_ID)?.data;
};
const trackInstallation = payload => {
  const widget = getInstallationWidget(payload);
  if (widget?.track) {
    trackEvent(widget.track);
  }
};

const showInstallationSnackbar = (payload, dispatch) => {
  const { snackbar_message: snackbarMessage } = getInstallationWidget(payload);
  if (snackbarMessage && snackbarMessage.message && snackbarMessage.show) {
    dispatch({
      type: SHOW_SNACKBAR,
      params: {
        ...snackbarMessage,
        type: snackbarMessage.type.toLowerCase(),
        delay: snackbarMessage.delay,
        called_from: INSTALLATION_SERVICE,
      },
    });
  }
};

module.exports = { trackInstallation, showInstallationSnackbar };
