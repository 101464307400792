const React = require('react');
const { shape, func } = require('prop-types');
const classnames = require('classnames');
const colornames = require('../../../lib/colornames');
const { namespace } = require('../utils');
const IconChevron = require('../../icons/chevron');
const customPropTypes = require('../utils/proptypes');
const { trackEvent } = require('../../../lib/tracking');

const SeeMoreVariations = ({ picker, limitedVariations, setPickersCollapse }) => (
  <p>
    <span
      data-testid="see-more-variations"
      className={classnames(`${namespace}__expand-action`, colornames(limitedVariations.label_dto.color))}
      onClick={() => {
        setPickersCollapse(picker.Id);
        trackEvent(limitedVariations.track);
      }}
      role="button"
      tabIndex="0"
      onKeyUp={() => {
        setPickersCollapse(picker.Id);
        trackEvent(limitedVariations.track);
      }}
    >
      {limitedVariations.label_dto.text}
      <IconChevron aria-hidden="true" className={classnames(`${namespace}__chevron ui-pdp-icon--chevron-down`)} />
    </span>
  </p>
);

SeeMoreVariations.propTypes = {
  picker: customPropTypes.picker,
  limitedVariations: shape(),
  setPickersCollapse: func,
};

module.exports = SeeMoreVariations;
