// global window
const merge = require('lodash/merge');
const restclient = require('nordic/restclient');
const { canUseDOM } = require('../../lib/dom-utils');

const DEFAULT_RESTCLIENT_OPTIONS = {
  headers: {
    common: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  },
  withCredentials: true,
};

module.exports = options => {
  const client = restclient(merge({}, DEFAULT_RESTCLIENT_OPTIONS, options));

  if (canUseDOM) {
    const token = document.querySelector('meta[name="csrf-token"]');
    if (token) {
      client.client.defaults.headers.common[client.client.defaults.xsrfHeaderName] = token.getAttribute('content');
    }
  }

  client.originalDoRequest = client.doRequest;
  client.doRequest = function doRequest(args) {
    return this.originalDoRequest(args).catch(err => {
      const res = err && err.response;
      if (res && res.status === 401 && res.data && res.data.data.login) {
        let stringLoginParams = '';
        if (args.loginParams) {
          /* istanbul ignore next */
          stringLoginParams = `&${new URLSearchParams(args.loginParams).toString()}`;
        }
        window.location = `${res.data.data.login}${stringLoginParams}`;
        return;
      }
      if (res && res.status === 301 && res.data.location) {
        window.location = res.data.location;
      }

      throw err;
    });
  };

  return client;
};
