const React = require('react');
const { shape, string } = require('prop-types');
const classNames = require('classnames');
const Typography = require('@andes/typography');

const namespace = 'ui-pdp-accessibility-texts';
const accessibilityText = 'jump_to_purchase_section';

const JumpToPurchase = ({ className, accesibilities }) => (
  <div className={classNames(namespace, className)}>
    {/* Passing component="a" and href should do the job */}
    <Typography component="a" color="link" href="#buybox-form" className={`${namespace}__buy`}>
      {accesibilities[`${accessibilityText}`]}
    </Typography>
  </div>
);

JumpToPurchase.propTypes = {
  className: string,
  accesibilities: shape({
    jump_to_purchase_section: string,
  }).isRequired,
};

JumpToPurchase.defaultProps = {
  className: null,
  accesibilities: [],
};

module.exports = JumpToPurchase;
