const React = require('react');
const { useContext, useState, useEffect } = require('react');
const PropTypes = require('prop-types');
const { Tooltip } = require('@andes/tooltip');
const { Button } = require('@andes/button');
const { FocusScope } = require('@react-aria/focus');
const StaticPropsContext = require('../context/static-props');
const ShareContent = require('./share-content.desktop');

/**
 * @TODO
 * workaround agregado con useContext debido a que <Tooltip>
 * pierde la prop Checked en los type Checkbox, por lo que no se puede tener
 * on render
 */
const namespace = 'ui-pdp-share';

const { arrayOf, shape, string } = PropTypes;

const Share = ({ action: { label }, shareActions }) => {
  const { lowEnd } = useContext(StaticPropsContext);
  const [visible, setVisible] = useState(false);
  const [isClose, setIsClose] = useState(false);

  useEffect(() => {
    if (isClose) {
      document.querySelector('#link__label').focus();
    }
  }, [isClose]);

  /* istanbul ignore next */
  const closeTooltip = () => {
    setVisible(false);
    setIsClose(true);
  };

  if (lowEnd) {
    return (
      <div className={namespace}>
        <input className={`${namespace}__link__input`} type="checkbox" id="share-desktop" />
        <div className={`${namespace}__link__tooltip`}>
          <ShareContent shareActions={shareActions} />
        </div>
        <span htmlFor="share-desktop" className={`${namespace}__link__label`}>
          {label.text}
        </span>
      </div>
    );
  }

  return (
    <div className={namespace}>
      <Tooltip
        className={`${namespace}__tooltip`}
        side="bottom"
        content={
          <FocusScope contain restoreFocus autoFocus>
            <ShareContent shareActions={shareActions} close={closeTooltip} />
          </FocusScope>
        }
        trigger="click"
        closable={false}
        onClose={closeTooltip}
        visible={visible}
      >
        <Button
          onClick={() => {
            setVisible(!visible);
            setIsClose(false);
          }}
          id="link__label"
          data-testid="ui-pdp-share__link__label"
          size="small"
          className={`${namespace}__link__label`}
          hierarchy="transparent"
          aria-expanded={visible}
        >
          {label.text}
        </Button>
      </Tooltip>
    </div>
  );
};

Share.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
    }).isRequired,
  }).isRequired,
  shareActions: arrayOf(
    shape({
      target: string.isRequired,
      icon: shape({
        id: string.isRequired,
      }),
    }),
  ).isRequired,
};

module.exports = Share;
