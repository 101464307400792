const React = require('react');
const { string } = require('prop-types');
const classnames = require('classnames');
const colornames = require('../../../lib/colornames');
const { namespace } = require('../utils');
const customPropTypes = require('../utils/proptypes');
const ApparelSizeSpecsComponents = require('./apparel-size-specs-components');

const ExtraDecorationHiddenPicker = ({ picker, pickerLabelId }) => (
  <div
    key={picker.id}
    data-testid="picker-container"
    className={classnames(`${namespace}__picker`, {
      [`${namespace}__picker-single`]: !picker.size_specs_chart,
      [`${namespace}__picker-size-specs-chart`]: picker.size_specs_chart,
    })}
  >
    {picker.label && (
      <p className={classnames(`${namespace}__label ${namespace}__label-only-text`, colornames(picker.label))}>
        {picker.label.text}
        <span
          id={pickerLabelId}
          className={classnames(`${namespace}__selected-label`, colornames(picker.selected_option))}
        >
          {picker.selected_option.text}
        </span>
        {picker.selected_option.subtitle && (
          <span
            className={classnames(
              `${namespace}__selected-subtitle`,
              colornames(picker.selected.selected_option.subtitle),
            )}
          >
            {picker.selected_option.subtitle.text}
          </span>
        )}
      </p>
    )}
    {picker.apparel_size_specs && <ApparelSizeSpecsComponents picker={picker} />}
  </div>
);

ExtraDecorationHiddenPicker.propTypes = {
  picker: customPropTypes.picker,
  pickerLabelId: string,
};

module.exports = ExtraDecorationHiddenPicker;
