import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import jsonToProps from '../../../../components/seller-data/json-to-props';
import actions from '../../../../actions';
import SellerData from '../../../../components/seller-data';

const FEATURE_NAME = 'seller_data';

const mapStateToProps = (
  { components: { [FEATURE_NAME]: sellerData }, recommendations_context, ...globalContext },
  ownProps,
) => jsonToProps(sellerData, recommendations_context, globalContext, ownProps);

const mapDispatchToProps = dispatch => ({
  onFollowClick: () => dispatch(actions.toggleFollowSeller()),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(SellerData);
export { mapDispatchToProps, mapStateToProps };
