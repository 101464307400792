import React from 'react';
import { string } from 'prop-types';
import Script from 'nordic/script';
import classNames from 'classnames';

/* Base Page */
import PdP from './main';

/** Available features */
import RelatedSearches from '../../../features/related-searches';

import Breadcrumb from '../../../features/breadcrumb';
import Share from '../../../features/share/share.desktop';
import Gallery from '../../../features/gallery/gallery.desktop';
import Header from '../../../features/header/header.desktop';
import OtherProducts from '../../../features/other-products/other-products.desktop';
import Bookmark from '../../../features/bookmark';
import Price from '../../../features/price';
import ShippingMessage from '../../../features/shipping-message';
import HeaderTopBrand from '../../../features/header-top-brand/header-top-brand.desktop';
import HeaderOfficialStore from '../../../features/header-official-store/header-official-store.desktop';
import RecommendationsCarouselSnappedHorizontalTop from '../../../features/recommendations/carousel-snapped-horizontal-neighbor';
import WarningMessage from '../../../features/warning-message';
import StockInformation from '../../../features/stock-information/stock-information.desktop';
import FulfillmentInformation from '../../../features/fulfillment-information';
import Reputation from '../../../features/reputation-seller';
import Promotions from '../../../features/promotions';
import PromotionTop from '../../../features/promotion-top';
import PromotionBottom from '../../../features/promotion-bottom';
import Highlights from '../../../features/highlights';
import Features from '../../../features/features';
import Variations from '../../../features/variations/variations.desktop';
import Description from '../../../features/description/description.desktop';
import HtmlDescription from '../../../features/html-description/html-description.desktop';
import OtherSellersNewItems from '../../../features/other-sellers-new-items';
import OtherSellersUsedRefurbished from '../../../features/other-sellers-used-refurbished';
import Specs from '../../../features/specs/specs.desktop';
import Reviews from '../../../features/reviews/reviews.desktop';
import ReviewsCapability from '../../../../../components/reviews-capability/reviews.desktop';
import QADB from '../../../features/qadb';
import Questions from '../../../features/questions/questions.desktop';
import Returns from '../../../features/returns/returns.desktop';
import CpgSummary from '../../../features/cpg/summary.desktop';
import ApparelSizeSpecPreview from '../../../features/apparel-size-spec-preview/apparel-size-specs-preview.desktop';
import OfficialStores from '../../../features/official-stores/official-stores.desktop';
import Buybox from '../../../features/buybox';
import BuyBoxOffers from '../../../features/buy-box-offers';
import QuantityDesktop from '../../../features/buybox/quantity/quantity.desktop';
import ShippingSummary from '../../../features/buybox/shipping';
import SecondShippingSummary from '../../../features/buybox/second-shipping';
import PickUpSummary from '../../../features/buybox/pick-up';
import ReturnsSummary from '../../../features/buybox/returns-summary/returns-summary.desktop';
import MeliplusLabel from '../../../features/buybox/meliplus-label/meliplus-label';
import MeliplusSummary from '../../../features/buybox/meliplus';
import UpsellingMeliplusSummary from '../../../features/buybox/upselling-meliplus';
import CouponSummary from '../../../features/buybox/coupon-summary/coupon-summary.desktop';
import PaymentSummary from '../../../features/buybox/payment/payment.desktop';
import CardWithThumbnails from '../../../features/card-with-thumbnails/card-with-thumbnails';
import Actions from '../../../features/buybox/actions';
import CartActions from '../../../features/buybox/cart-actions/cart-actions.desktop';
import SellerInfo from '../../../features/seller-info/seller-info.desktop';
import SellerData from '../../../features/seller-data';
import SellerExperiment from '../../../features/seller-info/seller-experiment.desktop';
import StickyWrapper from '../../../features/sticky-wrapper';
import Benefits from '../../../features/benefits';
import GroupedShareBookmark from '../../../features/grouped-share-bookmark';
import GiftRegistry from '../../../../vip/features/gift-registry';
import SYI from '../../../features/sell-your-item/sell-your-item';
import EYI from '../../../features/edit-your-item/edit-your-item';
import CreditsTooltip from '../../../features/credits-tooltip';
import PaypalTooltip from '../../../features/paypal-tooltip';
import PaymentMethods from '../../../features/payment-methods/payment-methods.desktop';
import MoqInformation from '../../../features/moq-information/moq-information.desktop';
import Onboarding from '../../../features/onboarding/onboarding.desktop';
import CPG from '../../../features/cpg/cpg.desktop';
import HighlightedSpecsFeatures from '../../../features/highlighted-specs/highlighted-specs-features.desktop';
import HighlightedSpecsAttrs from '../../../features/highlighted-specs/highlighted-specs-attrs.desktop';
import Seller from '../../../features/seller/seller-info.desktop';
import BillboardAd from '../../../../vip/features/billboard-ad';
import MerchEcosystem from '../../../features/merch-ecosystem';
import BillboardAdn from '../../../../vip/features/billboard-adn/billboard-adn-bottom';
import BillboardAdnUp from '../../../../vip/features/billboard-adn/billboard-adn-up';
import TradeIn from '../../../features/trade-in';
import Denounce from '../../../features/denounce';
import PricePerQuantityShortcuts from '../../../features/price-per-quantity-shortcuts';
import EnhancedDescription from '../../../features/enhanced-descriptions/enhanced-descriptions.desktop';
import ExtendedMenu from '../../../features/extended-menu/extended-menu.desktop';

// cbt
import CbtSummary from '../../../../../components/cbt/cbt-summary.desktop';

import CbtTaxesSummary from '../../../../../components/cbt-taxes/cbt-taxes-summary.desktop';
import CbtTaxesFcUsSummary from '../../../../../components/cbt-taxes-fc-us/cbt-taxes-fc-us-summary.desktop';

// Banner Meli+ BB
import GenericBanner from '../../../features/buybox/generic-banner';

import GenericBannerPrice from '../../../features/buybox/generic-banner-price';
import GenericBannerInstallments from '../../../features/buybox/generic-banner-installments';

// Recommendations
import RecommendationsCarouselSnapped from '../../../../vip/features/recommendations/carousel-snapped';
import RecommendationsListPads from '../../../../vip/features/recommendations/list-pads';
import RecommendationsShops from '../../../../vip/features/recommendations/shops';
import RecommendationsComparator from '../../../features/recommendations/comparator.desktop';
import RecommendationsCombos from '../../../../../components/combos/combos.desktop';
import ExitPopUp from '../../../features/exit-popup/exit-popup';
import RecommendationsCarouselSnappedHorizontal from '../../../../vip/features/recommendations/carousel-snapped-horizontal';
import RecommendationsShopBottom from '../../../../vip/features/recommendations/shops-bottom';

// Preload iframe
import PreloadIframe from '../../../features/preload-iframe/preload-iframe';

// On demand iframe
import OnDemandIframe from '../../../features/on-demand-iframe/on-demand-iframe';

import DynamicComponentWrapper from '../../../../vip/features/dynamic-component-wrapper';
import Snackbar from '../../../../vip/features/snackbar';
import TextJumpToPurchaseSectionDesktop from '../../../features/accessibility-texts/text-jump-to-purchase-section.desktop';
import renderSwapComponent from '../../../../vip/features/dynamic-component-wrapper/render-swap-component';
import { spotMiddleDesktopRender } from '../../../../../utils/spotMiddleRenders';

import HeaderBrandLogo from '../../../features/header-brand-logo/header_brand_logo.desktop';
import { VISIBILITY } from '../../../../../utils/constants';

// Compats
import InstalationService from '../../../features/instalation-service';

// Utils
import { isOfficialStoreHeaderVisible, isReviewsRowVisible } from '../../../../../utils/component-visibility';

/* Components */
const Grid = require('../../../../../components/grid');

const MeliDeliveryDaySummary = () => (
  <div className="mb-12">
    <MeliplusSummary />
    <CardWithThumbnails className="mt-4 mb-20" />
  </div>
);
// Dynamic Shipping Wrapper (for order swap)
const renderTopShipping = renderSwapComponent(
  'meliplus_summary',
  <MeliDeliveryDaySummary />,
  <ShippingSummary className="mb-12" />,
);
const PdPDesktop = ({ baseURL, siteId, ...rest }) => {
  const { csrfToken, initialState } = rest;
  const { components = {} } = initialState;
  const { reviews, reviews_capability_v3, related_searches } = components;

  const hasRelatedSearches = related_searches && related_searches.state !== VISIBILITY.HIDDEN;
  const hasReviewsRow = isReviewsRowVisible({ reviews, reviews_capability_v3 });
  const hasOfficialStorHeader = isOfficialStoreHeaderVisible(components);
  const headerGroupClassName = classNames('ui-pdp-container--top', {
    'ui-vpp-container-top--gray': hasOfficialStorHeader,
  });
  const itemContainer = classNames('ui-pdp-container--pdp', {
    'ui-vpp-container--bt-rect': hasOfficialStorHeader,
  });
  const questionsContainerClassName = classNames(
    { 'ui-pdp-with--separator': hasReviewsRow },
    'ui-pdp-with--separator--40',
  );
  const recosTopContainerClass = classNames({ 'ui-pdp-container--recos-top': !hasRelatedSearches });

  return (
    <PdP baseURL={baseURL} siteId={siteId} {...rest}>
      <div className={classNames('ui-pdp', { 'ui-pdp-official-store': hasOfficialStorHeader })}>
        <ExitPopUp url={initialState.parentUrl} />
        <OnDemandIframe />
        <PreloadIframe />
        <Onboarding />
        <CPG />
        <HeaderOfficialStore />
        <Grid.Container className={headerGroupClassName}>
          <Grid.Row className="ui-pdp-group-header">
            <Grid.Col size={6} className="ui-pdp-container--breadcrumb-related">
              <RelatedSearches />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col size={6} className={recosTopContainerClass}>
              <RecommendationsCarouselSnappedHorizontalTop
                featureName="carousel_pom_top"
                className="carousel-snapped-horizontal"
                lazy={false}
                preload
                fetchPriority="high"
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className="ui-pdp-group-header-breadcrumb">
            <Grid.Col size={4}>
              <Breadcrumb />
            </Grid.Col>
            <Grid.Col size={6} className="ui-pdp-container--share">
              <EYI />
              <SYI />
              <Share />
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
        <Grid.Container className={itemContainer}>
          <Grid.Row>
            <HeaderBrandLogo />
          </Grid.Row>
          <Grid.Row id="ui-pdp-main-container" className="ui-pdp--relative ui-pdp-with--separator--fluid pb-24">
            <Grid.Col className="ui-pdp-container--column-center pb-16" size={3}>
              <StickyWrapper columnName="center">
                <Grid.Row className="ui-pdp-with--separator--fluid ui-pdp-with--separator--40">
                  <Grid.Col className="ui-pdp--relative" size={2}>
                    <StickyWrapper>
                      <Gallery />
                    </StickyWrapper>
                  </Grid.Col>
                  <Grid.Col size={2} className="mr-32" style={{ maxWidth: '340px' }}>
                    <HeaderTopBrand />
                    <div className="ui-pdp-container__top-wrapper mt-40">
                      <Header>
                        <Highlights className="ui-pdp-highlights" />
                      </Header>
                      <Bookmark showLabel={false} csrfToken={csrfToken} />
                    </div>
                    <PromotionTop className="mt-16" />
                    <Price meta className="mt-16" size="large" />
                    <PricePerQuantityShortcuts className="mt-20" />
                    <PromotionBottom className="mt-24" />
                    <Promotions className="mt-12" />
                    <GenericBannerPrice className="mt-12" height={81.23} width={317} />
                    <GenericBannerInstallments className="mt-12" height={81.23} width={317} />
                    <CbtTaxesFcUsSummary />
                    <CouponSummary className="mt-24" />
                    <WarningMessage className="mt-24 mb-16" />
                    <Variations className="mt-24" />
                    <InstalationService className="mb-14" />
                    <TradeIn className="mt-21" />
                    <Features className="mt-24" />
                    <HighlightedSpecsFeatures className="mt-24" />
                    <OtherProducts />
                    <TextJumpToPurchaseSectionDesktop />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row className="ui-pdp-recommendations-row">
                  <Grid.Col>
                    <RecommendationsCombos className="pl-45 pr-45 ui-pdp-with--separator" />
                    <RecommendationsCarouselSnapped
                      featureName="carousel_above"
                      className="pl-45 pr-45 pb-40"
                      slidesPerView={3}
                      lazy={false}
                    />
                    <RecommendationsCarouselSnapped
                      featureName="carousel_up"
                      className="pl-45 pr-45 pb-40"
                      slidesPerView={3}
                      lazy={false}
                    />
                    <RecommendationsCarouselSnappedHorizontal
                      featureName="carousel_above_seller"
                      className="pl-45 pr-45 pt-40 ui-pdp-carousel-above-seller no-border ui-pdp-with--separator"
                      slidesPerView={2}
                      arrowsSize="small"
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col className="ui-pdp-with--separator ui-pdp-with--separator--40">
                    <ApparelSizeSpecPreview className="pl-45 pr-45" />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col className="ui-pdp-with--separator ui-pdp-with--separator--40">
                    <HighlightedSpecsAttrs />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col className="ui-pdp-with--separator ui-pdp-with--separator--40">
                    <Specs className="pl-45 pr-45" />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col className="pl-45 pr-45">
                    <EnhancedDescription className="pl-45 pr-45 ui-pdp-collapsable--is-collapsed" />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <Description className="pl-45 pr-45 ui-pdp-collapsable--is-collapsed" />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <HtmlDescription />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row className="ui-pdp-recommendations-row">
                  <Grid.Col>
                    <DynamicComponentWrapper
                      renderDynamicComponent={spotMiddleDesktopRender({
                        firstChildClassName: 'mt-40 mb-40 ui-pdp-spot-middle__separator',
                        middleChildClassName: 'mt-40 mb-40',
                        slidesPerView: 3,
                      })}
                      className="ui-pdp-spot-middle"
                      spot="spot_middle"
                    />
                  </Grid.Col>
                </Grid.Row>
              </StickyWrapper>
            </Grid.Col>
            <Grid.Col className="ui-pdp-container--column-right mt-16 pr-16">
              <StickyWrapper columnName="right">
                <Grid.Row>
                  <BuyBoxOffers>
                    <CpgSummary />
                    <CbtSummary />
                    <PaymentSummary className="mb-20" />
                    <MeliplusLabel className="ui-pdp-meliplus-label mb-12" />
                    <DynamicComponentWrapper renderDynamicComponent={renderTopShipping} spot="top_shipping" />
                    <GenericBanner className="mb-12" height={71} width={274} />
                    <CbtTaxesSummary />
                    <SecondShippingSummary className="mb-12" />
                    <UpsellingMeliplusSummary className="mb-12" />
                    <PickUpSummary className="mb-12" />
                    <ShippingMessage className="mb-14" />
                    <ReturnsSummary className="mb-12" />
                    <SellerInfo className="mb-20" />
                    <div className="ui-pdp-stock-and-full">
                      <StockInformation />
                      <FulfillmentInformation />
                    </div>
                    <QuantityDesktop className="mb-12" />
                    <MoqInformation />
                    <PaypalTooltip className="mb-14" />
                    <Actions parentUrl={baseURL} />
                    <CartActions />
                    <CreditsTooltip className="mt-16" />
                    <SellerExperiment className="mb-20 mt-20" />
                    <Reputation />
                  </BuyBoxOffers>
                  <Buybox>
                    <CpgSummary />
                    <CbtSummary />
                    <PaymentSummary className="mb-20" />
                    <MeliplusLabel className="ui-pdp-meliplus-label mb-12" />
                    <DynamicComponentWrapper renderDynamicComponent={renderTopShipping} spot="top_shipping" />
                    <GenericBanner className="mb-24" height={71} width={274} />
                    <CbtTaxesSummary />
                    <SecondShippingSummary className="mb-12" />
                    <UpsellingMeliplusSummary className="mb-12" />
                    <PickUpSummary className="mb-20" />
                    <ShippingMessage className="mb-14" />
                    <ReturnsSummary className="mb-12" />
                    <SellerInfo className="mb-20" />
                    <div className="ui-pdp-stock-and-full">
                      <StockInformation />
                      <FulfillmentInformation />
                    </div>
                    <QuantityDesktop className="mb-12" />
                    <MoqInformation />
                    <PaypalTooltip className="mb-14" />
                    <Actions parentUrl={baseURL} />
                    <SellerExperiment className="mb-20 mt-20" />
                    <Reputation />
                    <CartActions />
                    <CreditsTooltip className="mt-16" />
                    <Reputation />
                    <Returns className="mt-24" />
                    <Benefits className="mt-24" />
                    <GiftRegistry className="mt-24" />
                    <GroupedShareBookmark className="mt-24" showOnlyForWishlistVariant />
                  </Buybox>
                </Grid.Row>
                <Grid.Row>
                  <Seller />
                  <SellerData className="mt-24 ui-box-component-pdp__visible--desktop" />
                  <OtherSellersNewItems className="mt-24" />
                  <OtherSellersUsedRefurbished className="mt-24" />
                  <MerchEcosystem className="mt-24" />
                  <PaymentMethods />
                </Grid.Row>
                <Grid.Row>
                  <RecommendationsListPads
                    featureName="advertising_pdp_right"
                    className="ui-box-component-pdp__visible--desktop"
                  />
                </Grid.Row>
                <Grid.Row>
                  <RecommendationsShops featureName="advertising_pdp_right_mshops" className="mt-24" layout="pdp" />
                </Grid.Row>
                <Grid.Row>
                  <BillboardAdnUp />
                </Grid.Row>
              </StickyWrapper>
            </Grid.Col>
          </Grid.Row>
          <RecommendationsComparator />
          <Grid.Row id="ui-pdp-main-container">
            <Grid.Col size={3}>
              <Grid.Row>
                <Grid.Col className={questionsContainerClassName}>
                  <QADB className="pl-45 pr-45 mb-48" />
                  <Questions className="pl-45 pr-16 ppr-45 " />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Reviews className="pl-45 pr-45 mb-48" />
                  <ReviewsCapability className="pl-45 pr-45 mb-48" />
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
            <Grid.Col className="ui-pdp-container--column-right pr-16">
              <StickyWrapper columnName="right">
                <Grid.Row>
                  <RecommendationsShopBottom featureName="advertising_pdp_right_mshops_bottom" layout="pdp" />
                </Grid.Row>
              </StickyWrapper>
            </Grid.Col>
          </Grid.Row>
          <OfficialStores />
        </Grid.Container>
        <Grid.Container>
          <Denounce />
          <DynamicComponentWrapper
            renderDynamicComponent={featureName => (
              <RecommendationsCarouselSnapped className="mb-64 pb-16" featureName={featureName} />
            )}
            spot="carousel_bottom"
          />
        </Grid.Container>
        <Grid.Container className="ui-vip-core ui-pdp-container--bottom">
          <BillboardAd />
          <BillboardAdn />
          <ExtendedMenu />
        </Grid.Container>
        {siteId === 'MLB' && <Script src="block-extensions.js" inline />}
        <Snackbar />
      </div>
    </PdP>
  );
};

PdPDesktop.propTypes = {
  baseURL: string.isRequired,
  siteId: string.isRequired,
  csrfToken: string,
};

export default PdPDesktop;
