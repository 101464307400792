import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import Reputation from '../../../../components/seller-reputation-info/index';

const FEATURE_NAME = 'reputation';

const mapStateToProps = ({ components: { [FEATURE_NAME]: reputationseller, id } }) => ({
  ...reputationseller,
  id,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(Reputation);
