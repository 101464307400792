const React = require('react');
const { Pill } = require('@andes/badge');
const classnames = require('classnames');
const Button = require('@andes/button');
const { string } = require('prop-types');

const ExitPopupTitleComponent = ({ namespace, max_discount, title, url, button_title }) => (
  <div className={classnames(`${namespace}__div-modal-title`)}>
    <div className={classnames(`${namespace}__div-exit-popup-title`)}>
      {!!max_discount && (
        <div className={classnames(`${namespace}__title`)}>
          <Pill className={classnames(`${namespace}__badge-exit`)}>{max_discount}</Pill>
        </div>
      )}
      <p className={classnames(`${namespace}__title`)}>{title}</p>
    </div>

    <div className={classnames(`${namespace}__div-exit-popup-button`)}>
      {!!button_title && (
        <Button hierarchy="quiet" className={classnames(`${namespace}__exit-popup-button`)} href={`${url}`}>
          {button_title}
        </Button>
      )}
    </div>
  </div>
);

ExitPopupTitleComponent.propTypes = {
  namespace: string,
  max_discount: string,
  title: string,
  url: string,
  button_title: string,
};

ExitPopupTitleComponent.defaultProps = {
  namespace: 'ui-pdp-exit-popup',
};

module.exports = ExitPopupTitleComponent;
