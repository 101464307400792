import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';

const FEATURE_NAME = 'highlighted_specs_attrs';

const mapStateToProps = ({ components: { [FEATURE_NAME]: highlightedSpecs }, id }) => ({
  ...highlightedSpecs,
  productId: id,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

const highlightedSpecsAttrs = HighlightedSpecsAttrs => enhance(HighlightedSpecsAttrs);

export default highlightedSpecsAttrs;
