import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import BuyBox from '../../../../components/buybox';

const FEATURE_NAME = 'buy_box';

const mapStateToProps = ({ parentUrl }, ownProps) => ({
  parentUrl,
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(BuyBox);
