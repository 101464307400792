/* eslint-env browser */
const React = require('react');
const { shape, arrayOf, number, string, func, bool, objectOf } = require('prop-types');
const classnames = require('classnames');
const customPropTypes = require('./utils/proptypes');
const useMShopsTrackingInfo = require('../../hooks/use-mshops-tracking-info');
const { namespace } = require('./utils');
const DropdownPicker = require('./components/dropdown-picker');
const ExtraDecorationHiddenPicker = require('./components/extra-decoration-hidden-picker');
const DefaultPicker = require('./components/default-picker');

const VariationsDesktop = ({
  className,
  platform,
  pickers,
  selected_attributes,
  onSelectVariation,
  selectedQuantity,
  setPickersCollapse,
  hasDeferredErrors,
  shopsDefaultTrackingData,
  variation_id,
}) => {
  useMShopsTrackingInfo(shopsDefaultTrackingData, variation_id);

  const renderPicker = picker => {
    const pickerLabelId = `picker-label-${picker.id}`;
    const pickerSelectedSubtitleId = picker.selected_subtitle && `picker-selected-subtitle-${picker.id}`;

    if (picker.hide_extra_decoration) {
      return <ExtraDecorationHiddenPicker {...{ picker, pickerLabelId }} />;
    }
    if (picker.is_dropdown) {
      return (
        <DropdownPicker
          {...{
            picker,
            pickerLabelId,
            selected_attributes,
            platform,
            onSelectVariation,
            selectedQuantity,
            hasDeferredErrors,
          }}
        />
      );
    }

    return (
      <DefaultPicker
        {...{
          picker,
          pickerLabelId,
          pickerSelectedSubtitleId,
          platform,
          onSelectVariation,
          selectedQuantity,
          setPickersCollapse,
          selected_attributes,
          hasDeferredErrors,
        }}
      />
    );
  };

  return (
    <div className={classnames(namespace, className)}>
      {pickers.map(picker => (
        <React.Fragment key={picker.id}>{renderPicker(picker)}</React.Fragment>
      ))}
    </div>
  );
};

VariationsDesktop.propTypes = {
  className: string,
  platform: string,
  selected_attributes: objectOf(string),
  pickers: arrayOf(customPropTypes.picker).isRequired,
  selectedQuantity: number,
  onSelectVariation: func.isRequired,
  setPickersCollapse: func,
  hasDeferredErrors: bool,
  shopsDefaultTrackingData: shape({
    item_id: string,
    currency: string,
    price: number,
    title: string,
  }),
  variation_id: string,
};

VariationsDesktop.defaultProps = {
  selectedQuantity: 1,
  className: null,
  platform: null,
  selected_attributes: null,
  hasDeferredErrors: null,
  setPickersCollapse: null,
  variation_id: null,
  shopsDefaultTrackingData: null,
};

module.exports = VariationsDesktop;
