const React = require('react');
const { Action } = require('../../prop-types');
const { trackEvent } = require('../../lib/tracking');

const namespace = 'ui-pdp-syi';

const SellYourItem = ({ action }) => (
  <div className={namespace}>
    <a
      href={action.target}
      className={`${namespace}__link`}
      onClick={() => {
        trackEvent(action.track);
      }}
    >
      {action.label.text}
    </a>
  </div>
);

SellYourItem.propTypes = {
  action: Action.isRequired,
};

module.exports = SellYourItem;
