import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import ShippingMessage from '../../../../components/shipping-message';

const FEATURE_NAME = 'item_status_short_description_message';

const mapStateToProps = ({ components: { [FEATURE_NAME]: props }, ownProps }) => ({ ...props, ...ownProps });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { loader: false }),
  connect(mapStateToProps),
);

export default enhance(ShippingMessage);
