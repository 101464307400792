const React = require('react');
const PropTypes = require('prop-types');
const Thermometer = require('./thermometer');
const Description = require('./description');

const { arrayOf, number, shape, string } = PropTypes;

const namespace = 'ui-pdp-seller';
const MIN_ACCEPTABLE_REPUTATION = 2;

const Reputation = ({ thermometer: { rank, defaultInfo }, thermometer, officialStoreId }) => {
  const showThermometer = () => !officialStoreId || (officialStoreId && rank && rank > MIN_ACCEPTABLE_REPUTATION);
  return (
    <>
      {showThermometer() && <Thermometer rank={rank || 0} />}
      {!!thermometer.info?.length && <Description {...thermometer} />}
      {defaultInfo && (
        <div className={`${namespace}__extra-info`}>
          {defaultInfo.title && <h3 className={`${namespace}__extra-info__title`}>{defaultInfo.title.text}</h3>}
          {defaultInfo.subtitle && <p className={`${namespace}__extra-info__subtitle`}>{defaultInfo.subtitle.text}</p>}
        </div>
      )}
    </>
  );
};

Reputation.propTypes = {
  defaultInfo: shape({
    title: shape({
      text: string.isRequired,
      color: string.isRequired,
    }).isRequired,
    subtitle: shape({
      text: string.isRequired,
      color: string.isRequired,
    }).isRequired,
  }),
  officialStoreId: number,
  thermometer: shape({
    tagsReputationAlign: string,
    rank: number,
    info: arrayOf(
      shape({
        icon: shape({ id: string }),
        subtitle: string,
        title: string,
      }),
    ),
    defaultInfo: shape({
      subtitle: shape({
        color: string,
        text: string,
      }),
      title: shape({
        color: string,
        text: string,
      }),
    }),
  }).isRequired,
};

Reputation.defaultProps = {
  officialStoreId: null,
  defaultInfo: null,
};

module.exports = Reputation;
