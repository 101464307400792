const React = require('react');
const { string, number } = require('prop-types');

const ProgressBar = ({ className, percentage }) => (
  <div className={className}>
    <span className={`${className}__background`} />
    <span className={`${className}__fill-background`} style={{ width: `${percentage}%` }} />
  </div>
);

ProgressBar.propTypes = {
  className: string.isRequired,
  percentage: number,
};

ProgressBar.defaultProps = {
  percentage: 0,
};

module.exports = React.memo(ProgressBar);
