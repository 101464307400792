import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import { updateInstallation, removeInstallation } from '../../../../actions';
import withRow from '../../../../components/grid/row/with-row';
import { loadable } from 'nordic/lazy';

const InstallationService = loadable(() =>
  import('../../../../components/installation-service/components/container-installation'),
);

const FEATURE_NAME = 'installations';

const mapStateToProps = ({ components: { [FEATURE_NAME]: installations } }) => installations;

const mapDispatchToProps = dispatch => ({
  onUpdateInstallation: agency => dispatch(updateInstallation(agency)),
  onRemoveInstallation: () => dispatch(removeInstallation()),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);
export default enhance(InstallationService);
