import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import HeaderOfficialStore from '../../../../components/header-official-store/header-desktop';

const FEATURE_NAME = 'header_official_store';

const mapStateToProps = ({ components: { [FEATURE_NAME]: props } }) => props;

const enhance = compose(
  withFeatureFlag(`${FEATURE_NAME}`),
  connect(mapStateToProps),
);

export default enhance(HeaderOfficialStore);
