const React = require('react');
const { arrayOf, number, func, shape, string } = require('prop-types');
const { Tabs, Tab, TabContent } = require('@andes/tabs');
const ReviewComment = require('../review-comment/review-comment.desktop');
const IconEmptyReviews = require('../icons/empty-reviews');
const { trackEvent } = require('../../../../lib/tracking');

const namespace = 'ui-pdp-reviews__comments';

const Comments = ({ tabs, onDislikeComment, onLikeComment }) => {
  const onClick = (e, track) => {
    e.preventDefault();
    trackEvent(track);
  };
  return (
    <Tabs className={namespace}>
      {tabs &&
        tabs.map(tab => (
          <Tab key={`review_tab_${tab.label.text}`} title={tab.label.text} onClick={e => onClick(e, tab.track)}>
            <TabContent
              id={`tab-content-id-${tab.label.text.toLowerCase().replace(/\s/g, '-')}`}
              className={`${namespace}__tab-content`}
            >
              {tab.preview_reviews.length > 0 &&
                tab.preview_reviews.map(preview => (
                  <ReviewComment
                    {...preview}
                    onDislikeComment={onDislikeComment}
                    onLikeComment={onLikeComment}
                    key={`review_comment_${preview.id}`}
                  />
                ))}
              {tab.preview_reviews.length === 0 && tab.message && (
                <div className={`${namespace}__no-reviews`}>
                  <IconEmptyReviews />
                  <h1 className={`${namespace}__no-reviews__title`}>{tab.message.title.text}</h1>
                  <h2 className={`${namespace}__no-reviews__subtitle`}>{tab.message.subtitle.text}</h2>
                </div>
              )}
            </TabContent>
          </Tab>
        ))}
    </Tabs>
  );
};

Comments.propTypes = {
  tabs: arrayOf(
    shape({
      label: shape({
        text: string.isRequired,
      }),
      preview_reviews: arrayOf(
        shape({
          likes: number,
          dislikes: number,
          content: string,
          id: number.isRequired,
          rate: number,
          title: string,
          version: string,
        }),
      ),
    }),
  ).isRequired,
  onDislikeComment: func,
  onLikeComment: func,
};

Comments.defaultProps = {
  onDislikeComment: null,
  onLikeComment: null,
};

module.exports = Comments;
