const componentsIds = {
  INSTALATION_WIDGET_ID: 'installations_widget',
  INSTALATION_ID: 'installations',
  UPDATE_ACTION: 'UPDATE_ACTION',
  INSTALLATION_SERVICE: 'instalation_service',
  SHIPPING_ID_MESSAGE_MAP: 'shipping-agencies-maps-fe',
  REMOVE_ACTION: 'REMOVE_ACTION',
  HELP_ACTION: 'HELP_ACTION',
  OPERATION_FILL: 'FILL',
  OPERATION_REMOVE: 'REMOVE',
  POSITION_BODY: 'BODY',
  POSITION_HEADER: 'HEADER',
  ICON: 'ICON',
  LABEL: 'LABEL',
};

module.exports = { ...componentsIds };
