import get from 'lodash/get';
import connect from '../../../../store/connect';
import Metadata from '../../../../components/metadata';

const FEATURE_NAME = 'metadata';

const mapStateToProps = ({ components }) => {
  const tags = get(components, `${FEATURE_NAME}.tags`, null);
  const title = get(components, `${FEATURE_NAME}.title`, null);
  const url_canonical = get(components, `${FEATURE_NAME}.url_canonical`, null);
  return {
    tags,
    title,
    url_canonical,
  };
};

export default connect(mapStateToProps)(Metadata);
