const React = require('react');
const { arrayOf, shape } = require('prop-types');

// Basics
const ComponentList = require('../component-list');
const withMappedProps = require('../../utils/withMappedProps');

// Highlighted Specs Components
const StyledLabel = require('../styled-label');
const labelToProps = require('../styled-label/label-to-props');
const HSHighlightedFeatures = require('./components/hs-highlighted-features');
const useTrackView = require('../../hooks/use-track-view');

const namespace = 'ui-vpp-highlighted-specs';

const availableComponentsTop = {};
availableComponentsTop.label_component = withMappedProps(StyledLabel)(labelToProps);
availableComponentsTop.highlighted_features = HSHighlightedFeatures;

const HighlightedSpecsFeatures = ({ components, viewport_track }) => {
  const initialState = { components };
  const viewRef = useTrackView(viewport_track, { threshold: 0.45 });

  return (
    <section ref={viewRef} className={namespace}>
      <ComponentList availableComponents={availableComponentsTop} initialState={initialState} />
    </section>
  );
};

HighlightedSpecsFeatures.propTypes = {
  components: arrayOf(shape()).isRequired,
  viewport_track: shape({}),
};

HighlightedSpecsFeatures.defaultProps = {
  viewport_track: null,
};

module.exports = React.memo(HighlightedSpecsFeatures);
