const React = require('react');
const { arrayOf, shape, string, number } = require('prop-types');
const classNames = require('classnames');
const colornames = require('../../lib/colornames');
const MediaAction = require('../media/media-action');
const { PriceFontSize } = require('../../lib/num-font-size-enum');
const { trackEvent } = require('../../lib/tracking');
const { generateKeyToComponent } = require('../../utils/validators');

const namespace = 'ui-pdp-products';

const OtherProducts = ({ title, className, products }) => (
  <div className={classNames(namespace, className)}>
    {title && <p className={classNames(`${namespace}__label`, colornames(title))}>{title.text}</p>}
    <ul className={`${namespace}__list`}>
      {products.map(product => (
        <li key={generateKeyToComponent(product)} className={`${namespace}__item`}>
          <MediaAction
            target={product.action.target}
            className={`${namespace}__link`}
            label={product.title}
            num_font_size={PriceFontSize.OTHER_PRODUCT}
            onClick={() => trackEvent(product.action.track)}
          />
        </li>
      ))}
    </ul>
  </div>
);

OtherProducts.propTypes = {
  className: string,
  products: arrayOf(
    shape({
      title: shape({
        text: string.isRequired,
        color: string,
        values: shape({
          price: shape({
            type: string,
            value: number,
          }),
        }),
      }),
      action: shape({
        target: string.isRequired,
        track: shape({}),
      }),
    }),
  ).isRequired,
  title: shape({
    text: string.isRequired,
    color: string,
  }).isRequired,
};

OtherProducts.defaultProps = {
  className: null,
};

module.exports = OtherProducts;
