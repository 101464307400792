import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import mapStateToProps from './state-mapper-comparator';

const FEATURE_NAME = 'recommendations_comparator';

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps(FEATURE_NAME)),
);

const component = Component => enhance(Component);

export default component;
