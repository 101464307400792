const React = require('react');
const { shape, string } = require('prop-types');
const StyledLabel = require('../styled-label');
const StyledContainer = require('../styled-container');

const namespace = 'ui-pdp-questions';

const QuestionsMessage = ({ title, subtitle, background_color }) => (
  <StyledContainer className={`${namespace}__message-container`} bgColor={background_color}>
    <StyledLabel as="h3" className={`${namespace}__message-title`} {...title} />
    <StyledLabel as="h4" className={`${namespace}__message-subtitle`} {...subtitle} />
  </StyledContainer>
);

QuestionsMessage.propTypes = {
  title: shape({ text: string.isRequired }).isRequired,
  subtitle: shape({ text: string.isRequired }).isRequired,
  background_color: shape({ text: string.isRequired }).isRequired,
};

module.exports = QuestionsMessage;
