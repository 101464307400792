const {
  LIKE_REVIEW_OPTIMISTICALLY,
  LIKE_REVIEW_ROLLBACK,
  DISLIKE_REVIEW_OPTIMISTICALLY,
  DISLIKE_REVIEW_ROLLBACK,
  FETCH_ERROR,
} = require('../actions/actions');

const reviewsReducer = (state, action) => {
  switch (action.type) {
    case LIKE_REVIEW_OPTIMISTICALLY:
      return {
        ...state,
        comments: {
          ...state.comments,
          tabs: state.comments.tabs.map(tab => ({
            ...tab,
            preview_reviews: tab.preview_reviews.map(preview => {
              const newPreview =
                preview.id === action.payload.reviewId
                  ? {
                      ...preview,
                      isLiked: true,
                      likes: preview.likes + 1,
                    }
                  : {
                      ...preview,
                    };
              return newPreview;
            }),
          })),
        },
      };

    case LIKE_REVIEW_ROLLBACK:
      return {
        ...state,
        comments: {
          ...state.comments,
          tabs: state.comments.tabs.map(tab => ({
            ...tab,
            preview_reviews: tab.preview_reviews.map(preview => {
              const newPreview =
                preview.id === action.payload.reviewId
                  ? {
                      ...preview,
                      isLiked: false,
                      likes: preview.likes && preview.likes - 1,
                    }
                  : {
                      ...preview,
                    };
              return newPreview;
            }),
          })),
        },
      };

    case DISLIKE_REVIEW_OPTIMISTICALLY: {
      return {
        ...state,
        comments: {
          ...state.comments,
          tabs: state.comments.tabs.map(tab => ({
            ...tab,
            preview_reviews: tab.preview_reviews.map(preview => {
              const newPreview =
                preview.id === action.payload.reviewId
                  ? {
                      ...preview,
                      isDisliked: true,
                      dislikes: preview.dislikes + 1,
                    }
                  : {
                      ...preview,
                    };
              return newPreview;
            }),
          })),
        },
      };
    }
    case DISLIKE_REVIEW_ROLLBACK:
      return {
        ...state,
        comments: {
          ...state.comments,
          tabs: state.comments.tabs.map(tab => ({
            ...tab,
            preview_reviews: tab.preview_reviews.map(preview => {
              const newPreview =
                preview.id === action.payload.reviewId
                  ? {
                      ...preview,
                      isDisliked: false,
                      dislikes: preview.dislikes && preview.dislikes - 1,
                    }
                  : {
                      ...preview,
                    };
              return newPreview;
            }),
          })),
        },
      };
    case FETCH_ERROR:
      return { ...state, snackError: { key: Math.random(), show: true, message: action.error.message } };
    default:
      return state;
  }
};

module.exports = reviewsReducer;
