import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const FEATURE_NAME = 'description';

const mapStateToProps = ({ components: { [FEATURE_NAME]: description }, pathName }, ownProps) => ({
  ...description,
  maxPages: description.max_pages,
  minPages: description.min_pages,
  ...ownProps,
  pathName,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

const component = Component => enhance(Component);

export default component;
