const React = require('react');
const { shape, string } = require('prop-types');

const namespace = 'ui-pdp-questions__denounce-modal';

const QuestionsDenounceItemLink = ({ url, text }) => (
  <li className={`${namespace}__item`}>
    <a href={url} className={`${namespace}__item__link`} rel="noopener noreferrer" target="_blank">
      {text}
    </a>
  </li>
);

QuestionsDenounceItemLink.propTypes = {
  url: string.isRequired,
  text: string.isRequired,
};

const QuestionsDenounceModalContent = ({ denounceLinks, questionText, answerText }) => (
  <ul className={`${namespace}__list`}>
    <QuestionsDenounceItemLink url={denounceLinks.question} text={questionText} />
    <QuestionsDenounceItemLink url={denounceLinks.answer} text={answerText} />
  </ul>
);

QuestionsDenounceModalContent.propTypes = {
  denounceLinks: shape({ question: string, answer: string }).isRequired,
  questionText: string.isRequired,
  answerText: string.isRequired,
};

module.exports = QuestionsDenounceModalContent;
