const React = require('react');
const { forwardRef } = require('react');
const { oneOf, bool, func, shape, string, number } = require('prop-types');
const { Tooltip } = require('@andes/tooltip');
const classnames = require('classnames');
const Image = require('../../image');
const colornames = require('../../../lib/colornames');

const namespace = 'ui-pdp-thumbnail';

const ThumbnailWithTooltip = forwardRef(
  (
    {
      dataTestId,
      className,
      blocked,
      href,
      label,
      ariaLabel,
      modifier,
      modifierTooltip,
      size,
      picture,
      title,
      onClick,
      onMouseEnter,
      onMouseLeave,
      subtitle,
      style,
      ...rest
    },
    ref,
  ) => {
    const classes = classnames(namespace, className, {
      [`${namespace}--${modifier}`]: modifier,
    });

    const thumbnailProps = { href, modifier: 'link', className: classes, onClick };

    if ((modifier === 'NONE' && !modifierTooltip) || modifier === 'SELECTED') {
      thumbnailProps.title = title;
    }

    const content = picture ? (
      <div
        className={classnames(`${namespace}__picture`, { [`${namespace}__picture--${size}`]: size }, picture.className)}
      >
        <Image alt="" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} {...picture} {...rest} />
      </div>
    ) : (
      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={`${namespace}__container`}>
        {label && (
          <p {...rest} className={classnames(`${namespace}__label`)}>
            {label}
          </p>
        )}
        {subtitle && (
          <p className={classnames(`${namespace}__subtitle`, colornames(subtitle.color))}>{subtitle.text}</p>
        )}
      </div>
    );

    const CustomTag = blocked ? 'div' : 'a';

    return (
      <CustomTag
        aria-label={ariaLabel}
        aria-current={modifier === 'SELECTED' ? true : null}
        {...thumbnailProps}
        ref={ref}
        data-testid={dataTestId}
        style={{ ...style }}
      >
        {modifierTooltip ? (
          <Tooltip className={`${namespace}__tooltip`} side="bottom" content={modifierTooltip}>
            {content}
          </Tooltip>
        ) : (
          content
        )}
      </CustomTag>
    );
  },
);

ThumbnailWithTooltip.propTypes = {
  className: string,
  blocked: bool,
  href: string,
  label: string,
  ariaLabel: string,
  modifier: oneOf(['DISABLED', 'NONE', 'SELECTED', 'BLOCKED']),
  modifierTooltip: string,
  picture: shape({
    id: string.isRequired,
    lazyload: bool,
    src: string.isRequired,
    src2x: string,
  }),
  title: string,
  onClick: func,
  onMouseEnter: func,
  onMouseLeave: func,
  size: string,
  style: shape({
    minWidth: number,
    maxWidth: number,
  }),
  dataTestId: string,
  subtitle: shape({
    color: string,
    text: string,
  }),
};

ThumbnailWithTooltip.defaultProps = {
  className: '',
  blocked: false,
  href: null,
  label: null,
  ariaLabel: null,
  modifier: null,
  modifierTooltip: null,
  picture: null,
  title: null,
  onClick: () => {},
  onMouseEnter: null,
  onMouseLeave: null,
  size: 'large',
  dataTestId: null,
  subtitle: null,
};

module.exports = ThumbnailWithTooltip;
