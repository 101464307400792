const getApiResponse = tabs => {
  if (!tabs || tabs.length === 0) {
    return null;
  }

  const loaded = [];
  const recommendations = [];

  tabs.forEach((tab, index) => {
    loaded[index] = true;
    recommendations[index] = tab;
  });

  return { loaded, recommendations };
};

const mapStateToProps = featureName => (
  {
    siteId,
    id: productId,
    filters: pdp_filters,
    components: { [featureName]: comparator },
    recommendations_context: recommendationsContext,
  },
  ownProps,
) => {
  const { config } = comparator || {};
  const { tabs } = comparator || [];

  return {
    siteId,
    productId,
    pdp_filters,
    apiResponse: getApiResponse(tabs),
    itemId: config?.item_id || recommendationsContext?.item_id,
    ...config,
    ...ownProps,
  };
};

export default mapStateToProps;
