/* eslint-disable complexity */
const React = require('react');
const { useState, useRef, useCallback, useEffect, startTransition, useContext } = require('react');
const { bool, number, func, oneOfType, shape, string, arrayOf } = require('prop-types');
const { FocusScope } = require('@react-aria/focus');
const formatBulkQuantity = require('../../../utils/formatBulkQuantity');
const { Tooltip } = require('@andes/tooltip');
const { List } = require('@andes/list');
const { ListItem } = require('@andes/list');
const { MoneyAmount } = require('@andes/money-amount');

const classnames = require('classnames');
const QuantityInput = require('./quantity-input');
const QuantityTrigger = require('./quantity-trigger');
const { trackQuantityEvent, trackEvent } = require('../../../lib/tracking');
const HistoryService = require('../../../services/history');
const useOnClickOutside = require('../../../hooks/use-onclick-outside');
const componentEnhance = require('../../../lib/component-enhance');
const { runSchedulerTask } = require('../../../utils/validators');
const StaticPropsContext = require('../../context/static-props');

const namespace = 'ui-pdp-quantity-selector';
const optionsClassname = 'ui-pdp-buybox__quantity__trigger--options';

const QuantitySelectorDesktop = ({
  message,
  picker,
  quantitySelector,
  trigger: Trigger,
  setQuantity,
  showInput,
  onShowInput,
  buttonFocus,
}) => {
  const { layout } = useContext(StaticPropsContext);
  const yieldValue = quantitySelector?.selector?.template?.yield;
  const bulkSale = !!yieldValue;
  const ref = useRef();
  const triggerRef = useRef();
  const listPickerRef = useRef();
  const [visible, setVisible] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const initialQuantity = bulkSale ? picker.selected * yieldValue : picker.selected;
  const [localQuantity, setLocalQuantity] = useState(initialQuantity);

  useEffect(() => {
    if (isClose || buttonFocus) {
      document.querySelector('#quantity-selector').focus();
    }
  }, [isClose, buttonFocus]);

  useEffect(() => {
    let observer = null;
    if (triggerRef.current && visible) {
      observer = new IntersectionObserver(
        ([entry]) => {
          if (!entry.isIntersecting) {
            setVisible(false);
          }
        },
        {
          threshold: 0.1,
        },
      );

      observer.observe(triggerRef.current);
    }
    return () => observer && observer.disconnect();
  }, [visible]);

  useEffect(() => {
    if (visible) {
      trackEvent(picker.track);
    }
  }, [visible, picker.track]);

  useOnClickOutside(ref, e => {
    if (!triggerRef.current.contains(e.target)) {
      setVisible(false);
    }
  });

  const onTriggerClick = useCallback(e => {
    e.preventDefault();
    setVisible(!visible);
    setIsClose(false);
  }, [visible]);

  const showErrorMessage = picker && picker.error_message && !picker.error_message.is_deferred;

  const onClickListItem = ({ event, quantity, selected, typeInput }) => {
    if (event) {
      event.preventDefault();
    }

    let totalQuantity = quantity;
    if (bulkSale) {
      totalQuantity = Math.ceil(parseFloat((quantity / yieldValue).toFixed(4)));
    }

    if (totalQuantity !== selected || typeInput) {
      setLocalQuantity(bulkSale ? totalQuantity * yieldValue : totalQuantity);
      startTransition(() => {
        setQuantity(totalQuantity);
        trackQuantityEvent(quantitySelector.track, totalQuantity);
        runSchedulerTask(() => {
          HistoryService.pushParam('quantity', totalQuantity, true);
        }, 'background');
      });
    }
    setVisible(false);
  };

  const closeTooltip = e => {
    if (e.keyCode === 27) {
      setVisible(false);
      setIsClose(true);
    }
  };

  const hasOptions = !!(
    quantitySelector &&
    quantitySelector.selector.template.options &&
    quantitySelector.selector.template.options.length > 0
  );

  const hasVolumeDiscount = !!quantitySelector?.selector?.template?.volume_discount_options;

  const items = [];
  const minimumQuantity = bulkSale ? yieldValue : quantitySelector?.minimum_quantity || 1;

  const final_length =
    quantitySelector && !hasOptions
      ? quantitySelector.selector.rows + minimumQuantity - 1
      : quantitySelector.selector.rows;

  const { description, selected_label } = picker;
  const selectedLabelText =
    (!description && selected_label.values) || (bulkSale && selected_label.values)
      ? componentEnhance.jsx(selected_label.text, selected_label.values, 'ui-pdp-buybox__quantity__selected__label')
      : selected_label.text;

  if (quantitySelector) {
    let labelElement = index =>
      quantitySelector.selector?.template[(index === 1 ? 'singular' : 'plural')]?.replace('{quantity}', index);

    if (hasOptions) {
      const {
        selector: {
          template: { options },
        },
      } = quantitySelector;
      labelElement = index => componentEnhance.jsx(options[index - 1].text, options[index - 1].values);
    }

    const quantityIndex = hasOptions ? 1 : minimumQuantity;
    const offset = minimumQuantity - 1;

    for (let index = quantityIndex; index <= final_length; index += 1) {
      const restProps = {};
      let offsetIndex = index;
      if (hasOptions) {
        offsetIndex = bulkSale ? index * yieldValue : offset + index;
      }

      if (index === localQuantity) {
        restProps['data-autofocus'] = true;
      }

      const { quantity, amount } = hasVolumeDiscount
        ? quantitySelector.selector?.template?.volume_discount_options[index - 1]
        : {};

      items.push(
        <ListItem
          key={`list-item-quantity-${index}`}
          data-testid={`quantity-selector-item-${index}`}
          selected={offsetIndex === localQuantity}
          disabled={index > quantitySelector.available_quantity}
          onClick={event => onClickListItem({ event, quantity: offsetIndex, selected: localQuantity })}
          title={hasVolumeDiscount ? quantity.text : labelElement(index)}
          rightContent={
            hasVolumeDiscount && (
              <MoneyAmount
                size={16}
                value={{ fraction: amount.fraction, cents: amount.cents ?? null }}
                suffix={amount.suffix?.text}
                currencyId={amount.currency_id}
                centsType="superscript"
              />
            )
          }
          {...restProps}
          onKeyUp={closeTooltip}
        />,
      );
    }

    if (
      quantitySelector.selector.template.more &&
      quantitySelector.available_quantity > quantitySelector.selector.rows
    ) {
      const restProps = {};
      if (!showInput) {
        restProps.primary = quantitySelector.selector.template.more;
        restProps.onClick = () => {
          onShowInput(true);
        };
      }
      items.push(
        <ListItem
          key="link"
          data-testid="quantity-selector-item-more"
          selected={showInput}
          disabled={false}
          onKeyUp={closeTooltip}
          {...restProps}
        >
          {showInput && (
            <QuantityInput
              listPickerRef={listPickerRef}
              key={`list-item-quantity-${picker.title}`}
              confirm={quantitySelector.input.confirm_button.label.text}
              length={quantitySelector.selector.rows}
              noStockMessage={quantitySelector.input.error_message}
              minErrorMessage={quantitySelector.input.min_error_message}
              min={minimumQuantity}
              max={bulkSale ? quantitySelector.available_quantity * yieldValue : quantitySelector.available_quantity}
              quantity={bulkSale ? formatBulkQuantity(localQuantity) : localQuantity}
              bulkSale={bulkSale}
              setQuantity={quantity => onClickListItem({ quantity, selected: localQuantity, typeInput: true })}
              title={quantitySelector.selector.template.title ?? picker.title}
            />
          )}
        </ListItem>,
      );
    }
  }

  const listClasses = classnames({
    [`${namespace}__list--${layout}`]: hasVolumeDiscount && !bulkSale,
    [`${namespace}__list--default`]: !hasVolumeDiscount && !bulkSale,
    [`${namespace}__list--default-bulk`]: bulkSale,
  });

  return (
    <Tooltip
      id="quantity-selector"
      className={classnames(namespace, {
        [`${namespace}--options`]: hasOptions && !bulkSale,
        [`${namespace}--options-bulk`]: hasOptions && bulkSale,
        [`${namespace}--volume`]: hasVolumeDiscount,
      })}
      content={
        <FocusScope contain restoreFocus autoFocus>
          <div ref={ref} className={listClasses}>
            <List type={hasVolumeDiscount ? 'default' : 'dropdown'} srLabel={picker.title} ref={listPickerRef}>
              {items}
            </List>
          </div>
        </FocusScope>
      }
      offsetX={3}
      offsetY={2}
      side="bottomLeft"
      trigger="click"
      open={visible}
    >
      <Trigger
        ref={triggerRef}
        title={picker.title}
        selectedLabel={selectedLabelText}
        max={quantitySelector && quantitySelector.available_quantity}
        message={bulkSale ? null : message}
        active={visible}
        onClick={onTriggerClick}
        bulkSale={bulkSale}
        showError={showErrorMessage}
        className={classnames({ [optionsClassname]: hasOptions })}
      />
    </Tooltip>
  );
};

QuantitySelectorDesktop.propTypes = {
  message: string,
  picker: shape({
    description: string,
    title: string.isRequired,
    selected: number,
    selected_label: shape({
      text: string.isRequired,
    }).isRequired,
    track: shape({}),
  }).isRequired,
  quantitySelector: shape({
    type: string,
    available_quantity: number,
    selector: shape({
      title: shape({
        text: string,
        color: string,
      }),
      subtitles: arrayOf(
        shape({
          text: string,
          color: string,
          font_size: string,
          font_family: string,
        }),
      ),
      template: shape({
        singular: string,
        plural: string,
        more: string,
        volume_discount_options: arrayOf(
          shape({
            quantity: shape({
              text: string,
            }),
            amount: shape({
              fraction: string,
              cents: string,
              suffix: shape({
                text: string,
              }),
            }),
          }),
        ),
      }).isRequired,
      rows: number,
    }).isRequired,
    input: shape({
      title: shape({
        text: string,
        color: string,
      }),
      subtitles: arrayOf(
        shape({
          text: string,
          color: string,
          font_size: string,
          font_family: string,
        }),
      ),
      placeholder: string,
      confirm_button: shape({ label: shape({ text: string }) }),
      error_message: shape({
        text: string,
        color: string,
      }),
    }).isRequired,
    track: shape({}),
  }),
  setQuantity: func.isRequired,
  showInput: bool.isRequired,
  trigger: oneOfType([func, shape({ current: shape() })]),
  onShowInput: func.isRequired,
  buttonFocus: bool,
};

QuantitySelectorDesktop.defaultProps = {
  message: null,
  trigger: QuantityTrigger,
  quantitySelector: null,
  buttonFocus: false,
};

module.exports = QuantitySelectorDesktop;
