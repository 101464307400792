const React = require('react');
const { forwardRef } = require('react');
const { bool, func, node, shape, string } = require('prop-types');
const classnames = require('classnames');
const Image = require('../image');

const namespace = 'ui-pdp-thumbnail';

const Thumbnail = forwardRef(
  ({ className, picture, label, overlay, onClick, onKeyDown, isMultimedia, ...rest }, ref) => {
    const classes = classnames(namespace, className);
    const getSrc = src => (src.indexOf('youtube') !== -1 ? src.replace('hqdefault', 'default') : src);

    const thumbnailProps = { className: classes, onClick, onKeyDown };
    const thumbnailPicture = picture
      ? {
          id: picture.id,
          lazyload: picture.lazyload,
          src: getSrc(picture.src),
          src2x: getSrc(picture.src2x),
        }
      : null;

    const content = (
      <button
        type="button"
        className={classnames(`${namespace}__picture`, picture.className)}
        aria-label={picture.a11yLabel}
      >
        <Image alt="" isMultimedia={isMultimedia} {...thumbnailPicture} {...rest} />
      </button>
    );

    const overlayContent = overlay && <div className={`${namespace}--overlay`}>{overlay}</div>;

    return (
      <div role="presentation" {...thumbnailProps} ref={ref}>
        {overlayContent}
        {content}
      </div>
    );
  },
);

Thumbnail.propTypes = {
  className: string,
  picture: shape({
    id: string.isRequired,
    lazyload: bool,
    src: string.isRequired,
    src2x: string,
  }),
  label: string,
  overlay: node,
  onClick: func,
  onKeyDown: func,
  isMultimedia: bool,
};

Thumbnail.defaultProps = {
  className: '',
  picture: null,
  label: null,
  overlay: null,
  onClick: null,
  onKeyDown: null,
  isMultimedia: false,
};

module.exports = Thumbnail;
