import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import Benefits from '../../../../components/benefits';
import transformActionTarget from '../../../../utils/transformActionTarget';

const FEATURE_NAME = 'buy_benefits';

const mapStateToProps = ({ components: { [FEATURE_NAME]: benefits } }, ownProps) => {
  benefits.benefits.forEach(benefit => {
    if (benefit.values && benefit.values.action && benefit.values.action.type === 'modal') {
      transformActionTarget(benefit.values.action, { controlled: 'true' });
    }
  });

  return { ...benefits, ...ownProps };
};

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(Benefits);
