const React = require('react');
const { namespace } = require('../utils');
const customPropTypes = require('../utils/proptypes');
const FitAsExpected = require('../../apparel-size-specs/fit_as_expected.desktop');
const ApparelSizeSpecs = require('../../apparel-size-specs/apparel-size-specs.desktop');

const ApparelSizeSpecsComponents = ({ picker }) => (
  <div className={`${namespace}__picker-size-spec-sizechart-fit`}>
    {picker.apparel_size_specs.size_chart && (
      <ApparelSizeSpecs size_chart={picker.apparel_size_specs.size_chart} state="SHOW" inPicker />
    )}
    {picker.apparel_size_specs.fit_as_expected_action && (
      <FitAsExpected
        fit_as_expected={picker.apparel_size_specs.fit_as_expected_action}
        reviews_attribute_summary={picker.apparel_size_specs.reviews_attribute_summary}
        reviews_attribute_title={picker.apparel_size_specs.reviews_attribute_title}
      />
    )}
  </div>
);

ApparelSizeSpecsComponents.propTypes = {
  picker: customPropTypes.picker,
};

module.exports = ApparelSizeSpecsComponents;
