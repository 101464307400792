const { shape, arrayOf, number, string, oneOf, bool } = require('prop-types');

const picker = shape({
  id: string,
  label: shape({
    color: string,
    text: string,
  }),
  display_info: shape({
    show_limit: number,
    is_collapsed: bool,
    label_dto: shape({
      text: string,
      color: string,
    }),
  }),
  products: arrayOf(
    shape({
      permalink: string.isRequired,
      id: string,
      decoration_type: oneOf(['NONE', 'BLOCKED', 'DISABLED', 'SELECTED']),
      label: shape({
        color: string,
        text: string,
      }),
      picture: shape({
        id: string,
        src: string,
      }),
    }),
  ),
});

module.exports = { picker };
