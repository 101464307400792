import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import mapDispatchToProps from '../../../../components/qadb/functionsToProp';

import {
  QUICK_ACTIONS,
  SEARCH_BAR,
  MAKE_QUESTION_MODAL,
  MAKE_QUESTION,
  MAKE_QUESTION_SA,
  ADDITIONAL_PARAMETERS,
  SMART_ANSWER_MODAL,
} from '../../../../components/qadb/qadb.constants';

const QADB = loadable(() => import('../../../../components/qadb/qadb'));

const FEATURE_NAME = 'qadb';

const mapComponentsToProps = {
  [QUICK_ACTIONS]: 'quickActions',
  [SEARCH_BAR]: 'searchBar',
  [MAKE_QUESTION_MODAL]: 'questionModal',
  [MAKE_QUESTION]: 'makequestion',
  [MAKE_QUESTION_SA]: 'makequestion',
  [ADDITIONAL_PARAMETERS]: 'parameters',
  [SMART_ANSWER_MODAL]: 'smartAnswer',
};

const mapStateToProps = (
  {
    app,
    components: {
      [FEATURE_NAME]: {
        qadb_data,
        custom_message,
        track_view,
        track,
        title,
        snackMessage,
        isFetching,
        searchBar: searchBarComponent,
        makequestion: makequestionComponent,
        quickActions: quickActionsComponent,
        smartAnswer: smartAnswerComponent,
        parameters: parametersComponent,
        questionModal: questionModalComponent,
      },
    },
    id,
  },
  ownProps,
) => {
  let componentsData = null;

  const product_id = qadb_data ? qadb_data.product_id : null;
  const item_id = qadb_data ? qadb_data.item_id : null;

  const smartAnswerModal = { isOpen: false, isAsking: false };
  if (qadb_data && qadb_data.components) {
    componentsData = qadb_data.components.reduce(
      (prev, component) => {
        if (!prev[mapComponentsToProps[component.id]]) {
          prev[mapComponentsToProps[component.id]] = { ...component };
        }
        return prev;
      },
      {
        quickActions: quickActionsComponent,
        searchBar: searchBarComponent,
        questionModal: questionModalComponent,
        makequestion: makequestionComponent,
        parameters: parametersComponent,
        smartAnswer: smartAnswerComponent,
      },
    );
  }

  return {
    app,
    key: id,
    title: qadb_data ? qadb_data.title : title.text,
    smartAnswerModal,
    custom_message,
    components: qadb_data ? qadb_data.components : null,
    product_id,
    item_id,
    track_view,
    track,
    snackMessage,
    isFetching,
    ...componentsData,
    ...ownProps,
  };
};

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(QADB);
export { mapDispatchToProps };
