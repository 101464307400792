import compose from '../../../../../lib/compose';
import connect from '../../../../../store/connect';
import withFeatureFlag from '../../../../../components/feature-flag/with-feature-flag';

const FEATURE_NAME = 'payment_summary';

const mapStateToProps = ({ components: { [FEATURE_NAME]: paymentSummary } }, { className }) => ({
  ...paymentSummary,
  className,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

const component = Component => enhance(Component);

export default component;
