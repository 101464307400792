import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const FEATURE_NAME = 'returns';

const mapStateToProps = ({ components: { [FEATURE_NAME]: returns } }, ownProps) => ({
  ...returns,
  action: {
    ...returns.action,
    modalTitle: returns.action.modal_title,
    closeModalLabel: returns.action.close_modal_label,
  },
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

const component = Component => enhance(Component);

export default component;
