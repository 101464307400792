const React = require('react');
const { string } = require('prop-types');

const ICON_ID = 'dislike_full';

const DislikeFull = ({ className }) => (
  <svg
    data-testid="dislike-svg"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

DislikeFull.propTypes = {
  className: string,
};

DislikeFull.defaultProps = {
  className: null,
};

module.exports = React.memo(DislikeFull);
exports = module.exports;
exports.ICON_ID = ICON_ID;
