/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
const React = require('react');
const { useContext } = require('react');
const { bool, string, number, func } = require('prop-types');
const StaticPropsContext = require('../context/static-props');
const Image = require('../image');

const Figure = ({
  id,
  alt,
  className,
  decoding,
  preload,
  lazyload,
  src,
  src2x,
  onClick,
  index,
  zoom,
  width,
  height,
  fetchPriority,
}) => {
  const { deviceDpr } = useContext(StaticPropsContext);

  return (
    <>
      <Image
        id={id}
        className={className}
        alt={alt}
        preload={preload}
        lazyload={lazyload}
        src={deviceDpr && deviceDpr > '1' && src2x ? src2x : src}
        {...{ src2x: !deviceDpr && src2x ? src2x : null }}
        onClick={onClick}
        data-zoom={zoom}
        data-index={index}
        data-testid={`image-${id}`}
        width={width}
        height={height}
        decoding={decoding}
        fetchPriority={fetchPriority}
      />
    </>
  );
};

Figure.propTypes = {
  id: string.isRequired,
  alt: string,
  className: string,
  decoding: string,
  index: number,
  zoom: string,
  preload: bool,
  lazyload: bool,
  src: string.isRequired,
  src2x: string,
  onClick: func,
  width: number,
  height: number,
  fetchPriority: string,
};

Figure.defaultProps = {
  alt: null,
  className: null,
  decoding: 'async',
  preload: false,
  lazyload: true,
  src2x: null,
  onClick: null,
  index: null,
  zoom: null,
  width: null,
  height: null,
  fetchPriority: null,
};

module.exports = {
  Figure,
  Preview: Figure,
};
