const React = require('react');
const { arrayOf, string, shape, func, number } = require('prop-types');

const { trackEvent } = require('../../lib/tracking');

const componentEnhance = require('../../lib/component-enhance');

const StyledLabel = require('../styled-label');

const IconFactory = require('../icons/factory-builder')();

const { TagLimited, TagElement } = require('../tag-limited');

const namespace = 'ui-vpp-tags-pills';

const onClick = (pill, pillOnClick) => () => {
  pillOnClick();
  if (pill?.track) {
    trackEvent(pill.track);
  }
};

const TagPills = ({ className, pills, pillOnClick, id, plus_button, openModal, max_pills_to_show }) => {
  const renderPill = pill => {
    const { text, values, text_icon } = pill?.label || {};
    return (
      <TagElement className={`${namespace}__tag`} key={id}>
        {pill?.label && (
          <div>
            <StyledLabel
              className="ui-vpp-coupons__pill"
              key={text}
              as="p"
              onClick={onClick(pill, pillOnClick)}
              {...pill.label}
              text={IconFactory(text_icon)}
            >
              {componentEnhance.jsx(text, values)}
            </StyledLabel>
          </div>
        )}
      </TagElement>
    );
  };

  const onClickPlusButton = () => {
    openModal();
    if (plus_button?.track) {
      trackEvent(plus_button?.track);
    }
  };

  return (
    <TagLimited limit={max_pills_to_show} onClickPlusButton={onClickPlusButton} className={className}>
      {pills.map(renderPill)}
    </TagLimited>
  );
};

TagPills.propTypes = {
  pills: arrayOf(
    shape({
      label: shape({
        text: string.isRequired,
        color: string,
      }).isRequired,
      target: string,
      onClick: func,
      text_icon: shape({
        id: string,
        size: string,
      }).isRequired,
    }),
  ),
  openModal: func,
  plus_button: shape({
    target: string,
    onClick: func,
    track: shape({}).isRequired,
  }),
  pillOnClick: func,
  className: string,
  max_pills_to_show: number.isRequired,
  id: string.isRequired,
};

TagPills.defaultProps = {
  action: null,
  pillOnClick: null,
  pills: null,
  children: null,
  className: null,
  subtitles: null,
  openModal: null,
  max_pills_to_show: 3,
};

module.exports = React.memo(TagPills);
