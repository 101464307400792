const React = require('react');
const { shape, string, arrayOf, number } = require('prop-types');
const { useState, useCallback } = require('react');
const Tooltip = require('@andes/tooltip');
const classnames = require('classnames');
const RatingLevels = require('../rating-levels');
const ChevronIcon = require('../icons/chevron');
const applyClasses = require('../../lib/apply-classes');
const componentEnhance = require('../../lib/component-enhance');

const { trackEvent } = require('../../lib/tracking');

const namespace = 'ui-vpp-fit-as-expected';

const FitAsExpected = ({ fit_as_expected, reviews_attribute_summary, state, reviews_attribute_title }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClick = useCallback(e => {
    e?.preventDefault();
    setIsModalOpen(!isModalOpen);
    if (!isModalOpen) {
      trackEvent(fit_as_expected.track);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  if (state === 'HIDDEN' && !fit_as_expected) {
    return null;
  }

  return (
    <div className={namespace}>
      {fit_as_expected && (
        <Tooltip
          id="fit-as-expected"
          className={classnames(`${namespace}__tooltip`)}
          content={
            <div data-testid="tooltip-apparel">
              <RatingLevels title={reviews_attribute_title} levels={reviews_attribute_summary} />
            </div>
          }
          side="bottomRight"
          trigger="click"
          offsetY={8}
          offsetX={0.01}
          onClose={onClick}
        >
          <button
            type="button"
            className={classnames(
              `${namespace}__fit-as-expected`,
              { [`${namespace}__links-separator`]: fit_as_expected },
              applyClasses({ color: fit_as_expected.label.color, size: 'XXSMALL' }),
            )}
            onClick={onClick}
            data-testid="apparel-size-specs__fit-as-expected-button"
          >
            {componentEnhance.jsx(fit_as_expected.label.text, fit_as_expected.label.values)}
            <ChevronIcon
              className={classnames(`ui-pdp-icon--chevron-down ${namespace}__chevron`, {
                'ui-pdp-icon--chevron-down--active': isModalOpen,
              })}
            />
          </button>
        </Tooltip>
      )}
    </div>
  );
};

FitAsExpected.propTypes = {
  fit_as_expected: shape({}),
  state: string.isRequired,
  reviews_attribute_summary: arrayOf(
    shape({
      label: shape({
        text: string.isRequired,
      }).isRequired,
      percentage: number.isRequired,
      value: number.isRequired,
      value_label: shape({
        text: string.isRequired,
      }).isRequired,
    }),
  ),
  reviews_attribute_title: shape({
    text: string.isRequired,
    color: string.isRequired,
    font_size: string.isRequired,
    font_family: string.isRequired,
    values: shape({
      value: shape({
        text: string.isRequired,
        color: string.isRequired,
        font_size: string.isRequired,
        font_family: string.isRequired,
      }).isRequired,
    }).isRequired,
  }),
};

FitAsExpected.defaultProps = {
  fit_as_expected: null,
  reviews_attribute_summary: null,
  reviews_attribute_title: null,
};

module.exports = FitAsExpected;
