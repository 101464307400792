const React = require('react');
const { useCallback } = require('react');
const { arrayOf, string, shape, func } = require('prop-types');
const { VisuallyHidden } = require('@andes/common');
const { trackEvent } = require('../../lib/tracking');
const IconFactory = require('../icons/factory-builder')();

const namespace = 'ui-pdp-share__content';
const frames = ['facebook', 'twitter'];

// global window
const ShareContent = ({ shareActions, close }) => {
  const trapTabKey = e => {
    if (e.keyCode === 27) {
      close();
    }
  };

  const onClick = useCallback((e, target, icon, track) => {
    const id = icon.id.toLowerCase();
    if (frames.includes(id)) {
      e.preventDefault();
      trackEvent(track);

      const targetWindow = window.open(target, `${id}-popup`, 'height=350,width=600');
      if (targetWindow && targetWindow.focus) {
        targetWindow.focus();
      }
    }
  }, []);
  return (
    <div className={namespace} tabIndex="-1" id="share-content">
      <ul className={`${namespace}__list`}>
        {shareActions.map(({ icon, label, target, track }) => (
          <li className={`${namespace}__list__item`} key={`share_item_${icon.id}`}>
            <a
              onKeyDown={trapTabKey}
              data-testid={`share-on-click_${icon.id}`}
              rel="nofollow"
              href={target}
              onClick={e => {
                onClick(e, target, icon, track);
              }}
            >
              {label && label.text && <VisuallyHidden>{label.text}</VisuallyHidden>}
              {IconFactory(icon, `${namespace}__item__icon`)}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

ShareContent.propTypes = {
  shareActions: arrayOf(
    shape({
      target: string.isRequired,
      icon: shape({
        id: string.isRequired,
      }),
    }),
  ),
  close: func,
};

ShareContent.defaultProps = {
  shareActions: [],
  close: null,
};

module.exports = ShareContent;
