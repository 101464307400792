const React = require('react');
const { string } = require('prop-types');

const ICON_ID = 'poly_star';

const IconStarEmpty = ({ className }) => (
  <svg className={className} width="16.8" height="16" viewBox="0 0 11 10">
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconStarEmpty.propTypes = {
  className: string,
};

IconStarEmpty.defaultProps = {
  className: '',
};

module.exports = React.memo(IconStarEmpty);
exports = module.exports;
exports.ICON_ID = ICON_ID;
