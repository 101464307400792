import compose from '../../../../../lib/compose';
import connect from '../../../../../store/connect';
import withFeatureFlag from '../../../../../components/feature-flag/with-feature-flag';
import CouponSumary from '../../../../../components/buybox/coupon-summary/coupon-summary.desktop';
import actions from '../../../../../actions';

const FEATURE_NAME = 'coupon_summary';

const mapStateToProps = ({ components: { [FEATURE_NAME]: couponSummary, id } }, { className }) => ({
  ...couponSummary,
  id,
  className,
});

const mapDispatchToProps = dispatch => ({
  onCouponUpdate: () => dispatch(actions.fetchUpdatedCoupon()),
  showSnackbar: snackbar => dispatch(actions.showSnackbar(snackbar)),
  onCouponActivate: params => dispatch(actions.postCouponActivate(params)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(CouponSumary);
export { mapDispatchToProps };
