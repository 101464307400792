import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import Denounce from '../../../../components/denounce';

const FEATURE_NAME = 'denounce';

const mapStateToProps = ({ components: { [FEATURE_NAME]: denounce } }) => ({ ...denounce });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(Denounce);
