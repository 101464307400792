const React = require('react');
const { arrayOf, string, shape, func, oneOf } = require('prop-types');
const classnames = require('classnames');
const StyledLabel = require('../styled-label');
const componentEnhance = require('../../lib/component-enhance');
const { Checkbox } = require('@andes/checkbox');
const IconFactory = require('../icons/factory-builder')();

const namespace = 'ui-vpp-coupons-awareness';
const couponAwarenessCheckboxId = 'coupon-awareness-input-id';

const renderRedeem = (awareness, onCouponActivate) => {
  const text_icon = awareness?.label?.text_icon;
  switch (text_icon?.id) {
    case 'COUPON_AWARENESS_UNREDEEMED':
      return (
        <div className={`${namespace}__coupon-redeemed-container`}>
          <Checkbox
            checked={false}
            className={`${namespace}__checkbox`}
            onChange={onCouponActivate}
            id={couponAwarenessCheckboxId}
            inputProps={{ 'aria-labelledby': 'coupon-awareness-row-label coupon_summary-subtitles-style-label' }}
          />
        </div>
      );
    case 'COUPON_AWARENESS_REDEEMED':
      return (
        <div className={`${namespace}__coupon-redeemed-container`} aria-hidden="true">
          {IconFactory({ id: 'coupon_redeemed' }, `${namespace}__coupon-redeemed`)}
        </div>
      );
    default:
      return null;
  }
};

const AwarenessRow = ({ pills, awareness, onCouponActivate, checkboxLabelClassName }) => {
  const renderPill = pill => {
    const { text, values, text_icon } = pill?.label || {};
    return (
      <div className={`${namespace}__tag`} aria-hidden="true">
        {pill?.label && (
          <StyledLabel className={`${namespace}__pill`} key={text} as="p" {...pill.label} text={IconFactory(text_icon)}>
            {componentEnhance.jsx(text, values)}
          </StyledLabel>
        )}
      </div>
    );
  };

  return (
    <div className={classnames(namespace, { [`${namespace}--redemeed`]: !awareness?.is_tappable })}>
      {pills.map(renderPill)}
      {awareness?.label && (
        <>
          {awareness.label.text_icon && renderRedeem(awareness, onCouponActivate)}

          <StyledLabel
            for={couponAwarenessCheckboxId}
            className={classnames(`${namespace}__checkbox-label`, checkboxLabelClassName)}
            key={awareness.label.text}
            as="label"
            id="coupon-awareness-row-label"
            {...awareness.label}
            text=""
          >
            {componentEnhance.jsx(awareness.label.text, awareness.label.values)}
          </StyledLabel>
        </>
      )}
    </div>
  );
};

AwarenessRow.propTypes = {
  id: string,
  cupon: shape({}),
  checkboxLabelClassName: string,
  pills: arrayOf(shape({})).isRequired,
  onCouponActivate: func.isRequired,
  awareness: shape({
    status: oneOf(['COUPON_AWARENESS_UNREDEEMED', 'COUPON_AWARENESS_REDEEMED']),
    label: shape({
      text: string.isRequired,
      color: string,
      text_icon: shape({}),
    }),
  }),
};

module.exports = React.memo(AwarenessRow);
