const React = require('react');
const { arrayOf, shape, string } = require('prop-types');
const componentEnhance = require('../../../../lib/component-enhance');
const classnames = require('classnames');
const applyClasses = require('../../../../lib/apply-classes');

const RatingLevelsTitle = ({ className, title }) => (
  <p
    className={classnames(
      className,
      applyClasses({
        color: title.color,
        size: title.font_size,
        family: title.font_family,
      }),
    )}
  >
    {componentEnhance.jsx(title.text, title.values)}
  </p>
);

RatingLevelsTitle.propTypes = {
  className: string.isRequired,
  title: shape({
    text: string.isRequired,
    color: string,
    font_size: string,
    font_family: string,
    values: arrayOf(
      shape({
        value: shape({
          text: string.isRequired,
          color: string,
          font_size: string,
          font_family: string,
        }).isRequired,
      }),
    ),
  }).isRequired,
};

module.exports = RatingLevelsTitle;
