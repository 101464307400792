const { useEffect, useRef } = require('react');

const useStickyScroll = ({ isIE, dynamicScroll, columnName, wrapperMargin }) => {
  const ref = useRef();
  let scrollPosition = 0;
  let ticking = false;

  const handleScrollUp = ({ sticky, setElementTop, updateElementPosition }) => {
    const stickyTopIsOnViewport = sticky.getBoundingClientRect().top >= wrapperMargin;

    if (stickyTopIsOnViewport) {
      setElementTop(wrapperMargin);
    } else {
      updateElementPosition();
    }
  };

  const handleScrollDown = ({ sticky, setElementTop, updateElementPosition }) => {
    const viewportHeight = window.innerHeight;
    const stickyBottomIsOnViewport = sticky.getBoundingClientRect().bottom <= viewportHeight - wrapperMargin;
    const pixelsToStickyTop = `-${sticky.getBoundingClientRect().height - viewportHeight + wrapperMargin}`;

    if (stickyBottomIsOnViewport) {
      setElementTop(pixelsToStickyTop);
    } else {
      updateElementPosition();
    }
  };

  const onScroll = (sticky, column) => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        const newScrollPosition = window.scrollY;

        const pixelsToTranslate = Math.abs(column.getBoundingClientRect().top - sticky.getBoundingClientRect().top);

        const updateElementPosition = () => {
          sticky.style.top = '';
          sticky.style.transform = `translateY(${pixelsToTranslate}px)`;
        };

        const setElementTop = pixels => {
          sticky.style.top = `${pixels}px`;
          sticky.style.transform = '';
        };

        if (newScrollPosition > scrollPosition) {
          handleScrollDown({ sticky, setElementTop, updateElementPosition });
        } else {
          handleScrollUp({ sticky, setElementTop, updateElementPosition });
        }

        scrollPosition = newScrollPosition;
        ticking = false;
      });
    }
    ticking = true;
  };

  useEffect(() => {
    const sticky = ref.current;
    const column = columnName && document.querySelector(`.ui-pdp-container--column-${columnName}`);
    const compatibleAndEnabled = !isIE && dynamicScroll?.enabled;
    const stickyElementOverflowsViewport = window.innerHeight < sticky?.clientHeight;
    const shouldUseScroll = sticky && column && compatibleAndEnabled && stickyElementOverflowsViewport;
    const scrollHandler = () => onScroll(sticky, column);

    // Esto cubre el caso de la galeria o columnas de poco contenido
    if (compatibleAndEnabled && !stickyElementOverflowsViewport) {
      sticky.style.top = `${wrapperMargin}px`;
    }

    if (shouldUseScroll) {
      document.addEventListener('scroll', scrollHandler);
    }

    return () => {
      if (shouldUseScroll) {
        document.removeEventListener('scroll', scrollHandler);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnName, ref, dynamicScroll, isIE]);

  return ref;
};

module.exports = useStickyScroll;
