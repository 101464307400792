const React = require('react');
const { string, bool } = require('prop-types');
const classnames = require('classnames');
const colornames = require('../../../lib/colornames');
const { namespace, showError } = require('../utils');
const ErrorMessage = require('./error-message');
const IconFactory = require('../../icons/factory-builder')();
const customPropTypes = require('../utils/proptypes');

const PickerTitle = ({ picker, pickerLabelId, pickerSelectedSubtitleId, hovered }) => (
  <div className={`${namespace}__title`}>
    {picker.label && (
      <p
        className={classnames(`${namespace}__label`, colornames(picker.label), {
          [colornames(picker.error_message && picker.error_message.label.color)]: showError(picker) && !hovered,
        })}
      >
        {picker.label.text}
        <span className={classnames(`${namespace}__selected-label`, colornames(picker.selected_option))}>
          {picker.selected_option && picker.selected_option.text_icon && (
            <span id={`icon-${pickerLabelId}`}>
              {IconFactory(picker.selected_option.text_icon.id, colornames(picker.selected_option.text_icon.color))}
            </span>
          )}
          <span id={pickerLabelId} className={`${namespace}__selected-text`}>
            {picker.selected_option.text}
          </span>
          {picker.error_message && !picker.error_message.is_deferred && !hovered && (
            <ErrorMessage pickerId={pickerLabelId} error={picker.error_message.selected_option} />
          )}
        </span>
      </p>
    )}
    {picker.selected_subtitle && (
      <span
        id={pickerSelectedSubtitleId}
        className={classnames(`${namespace}__subtitle`, colornames(picker.selected_subtitle))}
      >
        {picker.selected_subtitle.text}
      </span>
    )}
  </div>
);

PickerTitle.propTypes = {
  picker: customPropTypes.picker,
  pickerLabelId: string,
  pickerSelectedSubtitleId: string,
  hovered: bool,
};

module.exports = PickerTitle;
