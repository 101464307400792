import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import PaymentMethods from '../../../../components/payment-methods/payment-methods.desktop';
import { withBoxComponent, MODAL } from '../../../../components/box-component/with-box-component.desktop';

const FEATURE_NAME = 'payment_methods';

const mapStateToProps = ({ components: { [FEATURE_NAME]: payment_methods } }, ...rest) => ({
  ...rest,
  ...payment_methods,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
  withBoxComponent({ type: MODAL }),
);

export default enhance(PaymentMethods);
