import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import Price from '../../../../components/price';
import actions from '../../../../actions';

const FEATURE_NAME = 'price';

const mapStateToProps = ({ components: { [FEATURE_NAME]: price } }, ownProps) => {
  const {
    price: {
      currency_symbol: currencySymbol,
      original_value: originalValue,
      value,
      price_reference: priceReference,
      range_price: rangePrice,
    },
    discount_label: discountLabel,
    discount_label_secondary: discountLabelSecondary,
    ...restPrice
  } = price;

  return {
    currencySymbol,
    originalValue,
    discountLabel,
    discountLabelSecondary,
    priceReference,
    value,
    rangePrice,
    ...ownProps,
    ...restPrice,
  };
};

const mapDispatchToProps = dispatch => ({
  showPaymentsSplitModal: ({ target }) => {
    dispatch(actions.showPaymentsSplitModal({ target }));
  },
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(Price);
