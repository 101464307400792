const React = require('react');
const { string, shape, arrayOf } = require('prop-types');
const { Image } = require('nordic/image');
const { Card } = require('@andes/card');
const { Thumbnail } = require('@andes/thumbnail');
const CarouselSnapped = require('@andes/carousel-snapped');
const classnames = require('classnames');
const StyledLabel = require('../styled-label');
const { generateKeyToComponent } = require('../../utils/validators');
const IconFactory = require('../icons/factory-builder')();
const useTrackView = require('../../hooks/use-track-view');

const { CarouselSnappedSlide } = CarouselSnapped;

const namespace = 'navigation-official-stores';

const Container = ({ carousel, title, navigation_action, view_carousel_track }) => {
  const viewRef = useTrackView(view_carousel_track, { threshold: 0.45 });

  return (
    <div ref={viewRef} className={classnames(namespace, 'row container')}>
      <div className={`${namespace}__header`}>
        <StyledLabel {...title} />
        <a href={navigation_action.target}>
          <StyledLabel className={`${namespace}__view-more`} {...navigation_action.label} />
        </a>
      </div>
      <div className={`${namespace}__carousel`}>
        <CarouselSnapped
          pagination={false}
          slidesPerView={4}
          spacing={16}
          arrowsVisible={false}
          lazyload
          strictBoundaries={false}
        >
          {carousel.map(slide => (
            <CarouselSnappedSlide key={generateKeyToComponent(slide)}>
              <a href={slide.target}>
                <Card>
                  <div className={`${namespace}__background`}>
                    <Image alt="" src={slide.banner.url.src} />
                  </div>
                  <div className={`${namespace}__divider`} />

                  <Thumbnail className={`${namespace}__logo`} modifier="square" size="80">
                    <Image alt={slide.title.text} src={slide.logo.url.src} />
                  </Thumbnail>
                  <div className={`${namespace}__description`}>
                    <div className={`${namespace}__description__title`}>
                      <StyledLabel {...slide.title} />
                      {slide.official_store_icon && IconFactory({ id: 'cockade' }, 'ui-pdp-cockade-icon')}
                    </div>
                    <StyledLabel {...slide.subtitle} />
                  </div>
                </Card>
              </a>
            </CarouselSnappedSlide>
          ))}
        </CarouselSnapped>
      </div>
    </div>
  );
};

Container.propTypes = {
  title: shape({ text: string, color: string, font_family: string, font_size: string }).isRequired,
  carousel: arrayOf(
    shape({
      state: string,
      title: shape({ text: string, color: string, font_family: string, font_size: string }),
      subtitle: shape({ text: string, color: string, font_family: string, font_size: string }),
      target: string,
      logo: shape({
        url: shape({ src: string }),
      }),
      banner: shape({
        url: shape({ src: string }),
      }),
    }).isRequired,
  ),
  navigation_action: shape({
    target: string,
    label: shape({ text: string, color: string, font_family: string, font_size: string }),
  }),
  view_carousel_track: shape({
    melidata_event: shape({
      path: string,
      event_data: shape({
        product_id: string,
        item_id: string,
        official_stores: arrayOf(string),
      }),
    }),
  }),
};

Container.defaultProps = {
  title: null,
  carousel: null,
  navigation_action: null,
  view_carousel_track: null,
};

module.exports = Container;
