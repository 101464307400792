const React = require('react');
const { bool, func, number, string } = require('prop-types');
const LikeIcon = require('../icons/like');
const LikeFullIcon = require('../icons/like-full');
const DislikeIcon = require('../icons/dislike');
const DislikeFullIcon = require('../icons/dislike-full');
const Stars = require('../stars');

const namespace = 'ui-pdp-reviews__comments__review-comment';

const couldLikeReview = (isLiked, isDisliked) => !(isLiked || isDisliked);

const ReviewComment = ({
  content,
  dislikes,
  id,
  isDisliked,
  isLiked,
  likes,
  rate,
  title,
  version,
  onDislikeComment,
  onLikeComment,
}) => (
  <div className={namespace}>
    <div aria-hidden="true">
      <Stars className={`${namespace}__rating`} value={rate} />
    </div>
    <p className={`${namespace}__title`}>{title}</p>
    {version && <p className={`${namespace}__version`}>{version}</p>}
    <p className={`${namespace}__comment`}>{content}</p>{' '}
    <div className={`${namespace}__likes`}>
      <span
        data-testid="like-button"
        onClick={e => {
          e.preventDefault();
          if (couldLikeReview(isLiked, isDisliked)) {
            onLikeComment(e, id);
          }
        }}
        aria-hidden="true"
      >
        {isLiked ? (
          <LikeFullIcon className={`${namespace}__likes__like`} />
        ) : (
          <LikeIcon className={`${namespace}__likes__like`} />
        )}
      </span>
      {likes}
      <span
        data-testid="dislike-button"
        onClick={e => {
          e.preventDefault();
          if (couldLikeReview(isLiked, isDisliked)) {
            onDislikeComment(e, id);
          }
        }}
        aria-hidden="true"
      >
        {isDisliked ? (
          <DislikeFullIcon className={`${namespace}__likes__dislike`} />
        ) : (
          <DislikeIcon className={`${namespace}__likes__dislike`} />
        )}
      </span>
      {dislikes}
    </div>
  </div>
);

ReviewComment.propTypes = {
  content: string,
  dislikes: number,
  id: number.isRequired,
  isDisliked: bool,
  isLiked: bool,
  likes: number,
  rate: number.isRequired,
  title: string,
  version: string,
  onDislikeComment: func,
  onLikeComment: func,
};

ReviewComment.defaultProps = {
  content: '',
  dislikes: 0,
  isLiked: false,
  isDisliked: false,
  likes: 0,
  title: '',
  version: null,
  onDislikeComment: null,
  onLikeComment: null,
};

module.exports = React.memo(ReviewComment);
