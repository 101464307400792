const React = require('react');
const { string, arrayOf, shape, bool } = require('prop-types');
const classnames = require('classnames');

const { useState, useContext } = React;
const Modal = require('@andes/modal');
const StaticPropsContext = require('../context/static-props');
const ComponentList = require('../component-list');
const ImageComponent = require('../image/image-component');
const StyledLabel = require('../styled-label/index');
const Separator = require('../grid/separator');
const Media = require('../media/media');
const Actions = require('../buybox/actions/actions');

const { hyphenatedbem } = require('../../lib/hyphenatedbem');

const { trackEvent } = require('../../lib/tracking');
const withMappedProps = require('../../utils/withMappedProps');
const useViewCounter = require('../../hooks/use-view-counter');
const { arrayIncludes } = require('../../lib/includes');
const { imageToProps } = require('../image/image-to-props');
const labelToProps = require('../styled-label/label-to-props');
const { mediaToProps } = require('../media/media-to-props');
const { emptySeparatorToProps } = require('../grid/separator/jsonToProps');

const namespace = 'onboarding';

const HIDDEN_CLOSE_BUTTON_IDS = ['cbt_modal', 'long_term_rental_modal'];

const availableComponents = {};
availableComponents.image_component = withMappedProps(ImageComponent)(imageToProps);
availableComponents.label_component = withMappedProps(StyledLabel)(labelToProps);
availableComponents.separator = withMappedProps(Separator)(emptySeparatorToProps);
availableComponents.generic_info_row = withMappedProps(Media)(mediaToProps);

const Onboarding = ({ id, components, track, storable, state, ariaLabel, type }) => {
  const { siteId } = useContext(StaticPropsContext);
  const [isVisible, setVisibility] = useState(state !== 'TRACK_HIDDEN');
  const initialState = { components };

  const updateCloseStatus = useViewCounter({
    id,
    visible: state === 'TRACK_HIDDEN' ? true : isVisible,
    storable,
    cookieName: 'modal-configuration',
  });

  const handleClose = () => {
    setVisibility(false);
    if (storable) {
      updateCloseStatus();
    }
    if (track) {
      trackEvent(track);
    }
  };

  const actionsToProps = props => ({
    ...props,
    actions: props.actions.map(action => ({ ...action, onClick: action.target ? null : handleClose })),
  });

  availableComponents.modal_actions = withMappedProps(Actions)(actionsToProps);

  return (
    <div key={id} className={namespace}>
      <Modal
        className={classnames(`${namespace}__modal`, hyphenatedbem(id), {
          [`cbt-modal--short`]: id === 'cbt_modal' && siteId === 'MLB',
        })}
        onClose={handleClose}
        open={isVisible}
        showCloseButton={!arrayIncludes(HIDDEN_CLOSE_BUTTON_IDS, id)}
        srLabel={ariaLabel}
        type={type}
        centsType="superscript"
      >
        <form className={`${namespace}__container`}>
          <ComponentList availableComponents={availableComponents} initialState={initialState} modifier="onboarding" />
        </form>
      </Modal>
    </div>
  );
};

Onboarding.propTypes = {
  id: string.isRequired,
  components: arrayOf(shape({})).isRequired,
  track: shape({}),
  storable: bool,
  state: string,
  actions: arrayOf(shape({})).isRequired,
  ariaLabel: string,
  type: string,
};

Onboarding.defaultProps = {
  track: null,
  storable: true,
  state: '',
  ariaLabel: '',
  type: 'small',
};

module.exports = React.memo(Onboarding);
