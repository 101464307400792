const React = require('react');
const { arrayOf, bool, shape, string } = require('prop-types');
const SpecOther = require('./spec-other');
const { ActionCollapsableCss } = require('../action-collapsable');

const SpecOtherCollapsable = ({ className, title, attributes, action, showTitle }) => (
  <ActionCollapsableCss action={action}>
    <SpecOther className={className} title={title} attributes={attributes} key={title} showTitle={showTitle} />
  </ActionCollapsableCss>
);

SpecOtherCollapsable.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string,
  }).isRequired,
  attributes: arrayOf(
    shape({
      id: string,
      text: string.isRequired,
    }),
  ).isRequired,
  className: string,
  title: string.isRequired,
  showTitle: bool,
};

SpecOtherCollapsable.defaultProps = {
  className: '',
  showTitle: true,
};

module.exports = SpecOtherCollapsable;
