const React = require('react');
const { Action } = require('../../prop-types');
const { trackEvent } = require('../../lib/tracking');

const namespace = 'ui-pdp-eyi';

const EditYourItem = ({ action }) => (
  <div className={namespace}>
    <a
      href={action.target}
      className={`${namespace}__link`}
      onClick={() => {
        trackEvent(action.track);
      }}
    >
      {action.label.text}
    </a>
  </div>
);

EditYourItem.propTypes = {
  action: Action.isRequired,
};

module.exports = EditYourItem;
