const React = require('react');
const useEnhancedReducer = require('../../../hooks/use-enhance-reducer');
const thunk = require('../../../store/middleware/thunk');
const reducer = require('../reducers/reviews');
const jsonToProps = require('../jsonToProps');

const Reviews = require('../reviews.desktop');
const actions = require('../actions/index');
const Grid = require('../../grid');

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line n/global-require
  const logger = require('../../../store/middleware/logger');
  middlewares.push(logger);
}

const mapDispatchToProps = dispatch => ({
  onDislikeComment: (e, reviewId) => {
    e.preventDefault();
    dispatch(actions.dislikeReview(reviewId));
  },

  onLikeComment: (e, reviewId) => {
    e.preventDefault();
    dispatch(actions.likeReview(reviewId));
  },
});

const ReviewsContainer = initialState => {
  const [internalState, dispatch] = useEnhancedReducer(reducer, initialState, middlewares);
  return <Reviews {...internalState} {...mapDispatchToProps(dispatch)} />;
};

const MappedReviews = props => (
  <Grid.Col size={3}>
    <ReviewsContainer {...props} />
  </Grid.Col>
);

module.exports = ReviewsContainer;
module.exports.MappedReviewsContainer = props => <MappedReviews {...jsonToProps(props)} />;
