const React = require('react');
const { string, arrayOf, shape, func } = require('prop-types');
const classnames = require('classnames');

// Basics
const ComponentList = require('../component-list');
const withMappedProps = require('../../utils/withMappedProps');

// Highlighted Specs Components
const StyledLabel = require('../styled-label');
const labelToProps = require('../styled-label/label-to-props');
const compatsToProps = require('../compats-widget/block/compats-to-props');
const HSHighlightedAttributes = require('./components/hs-highlighted-attributes');
const HSHighlightedAttributesList = require('./components/hs-highlighted-attributes-list');
const CompatsWidget = require('../compats-widget/block');
const HSDiscreteBar = require('./components/hs-discrete-bar');
const HSActionCollapsible = require('./components/hs-action-collapsible');
const { Row } = require('../grid');
const useTrackView = require('../../hooks/use-track-view');
const HSAttributes = require('./components/hs-attributtes');

const namespace = 'ui-vpp-highlighted-specs';
const HEIGHT_BOX = 400;
const HEIGHT_MIN = 1;

const availableComponentsTop = {};
availableComponentsTop.label_component = withMappedProps(StyledLabel)(labelToProps);
availableComponentsTop.compats_widget_block = withMappedProps(CompatsWidget)(compatsToProps);
availableComponentsTop.composed_highlight = withMappedProps(CompatsWidget)(compatsToProps);
availableComponentsTop.highlighted_attributes = HSHighlightedAttributes;
availableComponentsTop.highlighted_attributes_list = HSHighlightedAttributesList;
availableComponentsTop.discrete_bar = HSDiscreteBar;

const HIGHLIGHTED_NEW_EXPERIENCE = 'highlighted_specs_attrs_new';

const HighlightedSpecsAttrs = ({ component_id, components, viewport_track, className, getDispatch, feature_name }) => {
  const initialState = { components: components?.map(c => ({ ...c, getDispatch, feature_name })) };
  const viewRef = useTrackView(viewport_track, { threshold: 0.45 });

  const leftAttributes = components.filter(
    c => (c.type === 'continuous_bar' || c.type === 'key_value') && c.column === 'LEFT',
  );

  const rightAttributes = components.filter(
    c => (c.type === 'continuous_bar' || c.type === 'key_value') && c.column === 'RIGHT',
  );

  const techSpecs = components.find(c => c.id === 'technical_specifications');
  const existAttributes = leftAttributes.length > 0 || rightAttributes.length > 0;

  return (
    <section
      ref={viewRef}
      id={component_id}
      className={classnames(namespace, className, {
        'ui-vpp-highlighted-specs-rex': feature_name === HIGHLIGHTED_NEW_EXPERIENCE,
      })}
    >
      <ComponentList availableComponents={availableComponentsTop} initialState={initialState} />
      {existAttributes ? (
        <Row modifier="attributes">
          <div className={`${namespace}__attribute-columns`}>
            <HSAttributes dataList={leftAttributes} namespace={namespace} />
            <HSAttributes dataList={rightAttributes} namespace={namespace} />
          </div>
        </Row>
      ) : null}
      {techSpecs ? (
        <Row modifier="technical-specifications">
          <HSActionCollapsible {...techSpecs} maxCollapsed={existAttributes ? HEIGHT_MIN : HEIGHT_BOX} />
        </Row>
      ) : null}
    </section>
  );
};

HighlightedSpecsAttrs.propTypes = {
  className: string,
  component_id: string,
  components: arrayOf(shape()).isRequired,
  viewport_track: shape({}),
  getDispatch: func,
  feature_name: string,
};

HighlightedSpecsAttrs.defaultProps = {
  className: 'pl-45 pr-45',
  viewport_track: null,
};

module.exports = React.memo(HighlightedSpecsAttrs);
