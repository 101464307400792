const React = require('react');
const { string } = require('prop-types');

const ICON_ID = 'like';

const Like = ({ className }) => (
  <svg data-testid="like-svg" className={className} width="20" height="20" viewBox="0 0 20 20">
    <use href={`#${ICON_ID}`} />
  </svg>
);

Like.propTypes = {
  className: string,
};

Like.defaultProps = {
  className: null,
};

module.exports = React.memo(Like);
exports = module.exports;
exports.ICON_ID = ICON_ID;
