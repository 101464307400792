/**
 * Styles
 */
require('../../../pages/pdp/styles/pdp.desktop.scss');

/**
 * Polyfills
 */
require('drift-zoom/dist/Drift.min');

/**
 * Module dependencies
 */
const bootstrap = require('../../bootstrap');
const PdP = require('../../../pages/pdp/components/views/main/main.desktop').default;

bootstrap(PdP);
