const React = require('react');
const { RecommendationsComparatorDesktop } = require('@recommendations-fe/comparator');
const Grid = require('../grid');
const RecommendationsIconFactory = require('./icon-factory');

module.exports = props => (
  <Grid.Row>
    <Grid.Col size={3}>
      <Grid.Row className="ui-pdp-container__comparator">
        <Grid.Col className="pl-45 pr-45">
          <RecommendationsComparatorDesktop IconFactory={RecommendationsIconFactory} {...props} />
        </Grid.Col>
      </Grid.Row>
    </Grid.Col>
  </Grid.Row>
);
