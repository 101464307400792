import compose from '../../../../../lib/compose';
import connect from '../../../../../store/connect';
import withFeatureFlag from '../../../../../components/feature-flag/with-feature-flag';
import ShippingSummary from '../../../../../components/buybox/shipping';
import actions from '../../../../../actions';

const FEATURE_NAME = 'meliplus_summary';

const MELIPLUS_PROPS = {
  noTitle: true,
  id: FEATURE_NAME,
};

const mapStateToProps = ({ components: { [FEATURE_NAME]: meliplusSummary } }, ownProps) => ({
  ...meliplusSummary,
  ...ownProps,
  ...MELIPLUS_PROPS,
});

const serializeOwnProps = (state, ownProps) => ({
  ...ownProps,
  ...MELIPLUS_PROPS,
});

const mapDispatchToProps = dispatch => ({
  meliplusOnDeliveryDayChange: () => {
    dispatch(actions.fetchProductOnMeliplusUpdate('change_day'));
  },
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const noFeatureFlagConnect = connect(
  serializeOwnProps,
  mapDispatchToProps,
);

export default enhance(ShippingSummary);
export const noFeatureFlag = noFeatureFlagConnect(ShippingSummary);
