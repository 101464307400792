const React = require('react');
const { number, string, func, bool, objectOf } = require('prop-types');
const classnames = require('classnames');
const colornames = require('../../../lib/colornames');
const { showError, handleOnSelectVariation, namespace } = require('../utils');
const ErrorMessage = require('./error-message');
const IconFactory = require('../../icons/factory-builder')();
const VariationsDropdown = require('../variations-dropdown');
const customPropTypes = require('../utils/proptypes');
const ApparelSizeSpecsComponents = require('./apparel-size-specs-components');

const DropdownPicker = ({
  picker,
  pickerLabelId,
  selected_attributes,
  platform,
  onSelectVariation,
  selectedQuantity,
  hasDeferredErrors,
}) => (
  <div
    key={picker.id}
    className={classnames(`${namespace}__picker`, {
      [`${namespace}__picker-size-specs-chart`]: picker.size_specs_chart,
    })}
    data-testid="picker-container"
  >
    <div className={`${namespace}__title`}>
      {picker.label && (
        <p
          className={classnames(
            `${namespace}__label`,
            colornames(picker.label),
            {
              [`${namespace}__label--with-subtitle`]: picker.selected_subtitle,
            },
            { [colornames(picker.error_message && picker.error_message.label.color)]: showError(picker) },
          )}
        >
          {picker.label.text}
          <span
            id={pickerLabelId}
            className={classnames(
              `${namespace}__selected-label`,
              colornames(showError(picker) ? picker.error_message.selected_option.color : picker.selected_option),
            )}
          >
            {picker.selected_option && !picker.outer_message && picker.selected_option?.text && (
              <span className={classnames(`${namespace}__selected-text`, colornames(picker.selected_option.color))}>
                {picker.selected_option.text}
              </span>
            )}
            {selected_attributes && !selected_attributes[picker.id] && picker.outer_message && (
              <span className={classnames(`${namespace}__selected-text`, colornames(picker.outer_message.color))}>
                {picker.outer_message.text_icon &&
                  IconFactory(picker.outer_message.text_icon.id, colornames(picker.outer_message.text_icon.color))}
                {picker.outer_message.text}
              </span>
            )}
            {showError(picker) && <ErrorMessage pickerId={pickerLabelId} error={picker.error_message.outer_message} />}
          </span>
          {picker.selected_option.subtitle && (
            <span>
              <span className={classnames(`${namespace}__selected-title`, colornames(picker.selected_option))}>
                {picker.selected_option.text}
              </span>
            </span>
          )}
        </p>
      )}
      {picker.selected_subtitle && (
        <p className={classnames(`${namespace}__subtitle`, colornames(picker.selected_subtitle))}>
          {picker.selected_subtitle.text}
        </p>
      )}
    </div>
    <VariationsDropdown
      selectedAttributes={selected_attributes}
      selectedAttributeId={selected_attributes ? selected_attributes[picker.id] : null}
      handleOnSelectVariation={handleOnSelectVariation}
      onSelectVariation={onSelectVariation}
      picker={picker}
      selectedQuantity={selectedQuantity}
      hasDeferredErrors={hasDeferredErrors}
      platform={platform}
    />
    {picker.apparel_size_specs && <ApparelSizeSpecsComponents picker={picker} />}
  </div>
);

DropdownPicker.propTypes = {
  picker: customPropTypes.picker,
  pickerLabelId: string,
  selected_attributes: objectOf(string),
  platform: string,
  selectedQuantity: number,
  onSelectVariation: func.isRequired,
  hasDeferredErrors: bool,
};

module.exports = DropdownPicker;
