const extractActivateCouponParams = (awareness, actionTarget) => {
  if (awareness?.params) {
    return {
      loyalty: awareness?.params?.loyalty,
      unit_price: awareness?.params?.unit_price,
      campaign_id: awareness?.params?.campaign_id,
      code: awareness?.params?.code,
    };
  }

  if (actionTarget) {
    const url = new URL(actionTarget);
    const params = url.searchParams;
    return {
      loyalty: params.get('loyalty'),
      unit_price: params.get('unit_price'),
      campaign_id: awareness?.campaign?.id,
      code: awareness?.campaign?.code,
    };
  }

  return {};
};

const handleAwarenessInteraction = ({ onCouponActivate, openModal, awareness, actionTarget, isFetching }) => {
  if (awareness) {
    if (isFetching) {
      return null;
    }
    const params = extractActivateCouponParams(awareness, actionTarget);
    if (awareness?.track) {
      params.track = awareness.track;
    }
    return awareness?.is_tappable && onCouponActivate?.(params);
  }
  return openModal?.();
};

module.exports = handleAwarenessInteraction;
