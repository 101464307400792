import OtherProductsDesktop from '../../../../components/other-products/other-products.desktop';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const FEATURE_NAME = 'others_products';

const mapStateToProps = ({ components: { [FEATURE_NAME]: otherProducts } }, ownProps) => ({
  ...ownProps,
  ...otherProducts,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(OtherProductsDesktop);
