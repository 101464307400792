const React = require('react');
const { bool, arrayOf, shape, string } = require('prop-types');
const List = require('../list');

const SpecOther = ({ className, title, attributes, showTitle }) => (
  <React.Fragment key={title}>
    {showTitle && <h3 className={`${className}__subtitle`}>{title}</h3>}
    {attributes && <List items={attributes} className={`${className}__list`} />}
  </React.Fragment>
);

SpecOther.propTypes = {
  attributes: arrayOf(
    shape({
      id: string,
      text: string.isRequired,
    }),
  ),
  className: string,
  title: string.isRequired,
  showTitle: bool,
};

SpecOther.defaultProps = {
  attributes: null,
  className: '',
  showTitle: true,
};

module.exports = SpecOther;
