/* eslint-disable react-hooks/exhaustive-deps */
const React = require('react');
const { number, string, func, objectOf, bool } = require('prop-types');
const debounce = require('lodash/debounce');
const classnames = require('classnames');
const { namespace } = require('../utils');
const customPropTypes = require('../utils/proptypes');
const ApparelSizeSpecsComponents = require('./apparel-size-specs-components');
const PickerTitle = require('./picker-title');
const ProductsPicker = require('./products-picker');
const SeeMoreVariations = require('./see-more-variations');

const DefaultPicker = ({
  picker,
  pickerLabelId,
  pickerSelectedSubtitleId,
  platform,
  onSelectVariation,
  selectedQuantity,
  setPickersCollapse,
  selected_attributes,
  hasDeferredErrors,
}) => {
  const limitedVariations = picker.display_info;
  const [hovered, __setHovered] = React.useState(false);
  const setHovered = React.useCallback(debounce(__setHovered, 200), []);
  return (
    <div
      key={picker.id}
      className={classnames(`${namespace}__picker`, {
        [`${namespace}__picker-size-specs-chart`]: picker.size_specs_chart,
      })}
      data-testid="picker-container"
    >
      <PickerTitle {...{ picker, pickerLabelId, pickerSelectedSubtitleId, hovered }} />
      <ProductsPicker
        {...{
          picker,
          pickerLabelId,
          pickerSelectedSubtitleId,
          limitedVariations,
          platform,
          onSelectVariation,
          selectedQuantity,
          selected_attributes,
          hasDeferredErrors,
          setHovered,
        }}
      />

      {!!limitedVariations?.is_collapsed && (
        <SeeMoreVariations {...{ picker, limitedVariations, setPickersCollapse }} />
      )}

      {picker.apparel_size_specs && <ApparelSizeSpecsComponents picker={picker} />}
    </div>
  );
};

DefaultPicker.propTypes = {
  picker: customPropTypes.picker,
  pickerLabelId: string,
  pickerSelectedSubtitleId: string,
  platform: string,
  selectedQuantity: number,
  onSelectVariation: func.isRequired,
  setPickersCollapse: func,
  selected_attributes: objectOf(string),
  hasDeferredErrors: bool,
};

module.exports = DefaultPicker;
