const React = require('react');
const { bool, func, string } = require('prop-types');
const classNames = require('classnames');
const colornames = require('../../../lib/colornames');
const Button = require('@andes/button');
const ChevronIcon = require('../../icons/chevron');
const IconFactory = require('../../icons/factory-builder')();

const namespace = 'ui-pdp-buybox__quantity';

const QuantityTrigger = React.forwardRef(
  ({ title, description, showError, disabled, selectedLabel, bulkSale, message, onClick, active }, ref) => (
    <div ref={ref}>
      <Button
        ref={ref}
        id="quantity-selector"
        data-testid="quantity-selector-trigger"
        aria-describedby="quantity-selector"
        className={classNames({
          [`${namespace}__trigger-bulksale`]: bulkSale,
          [`${namespace}__trigger`]: !bulkSale,
        })}
        hierarchy="transparent"
        onClick={onClick}
        disabled={disabled}
      >
        <span
          className={classNames({
            [`${namespace}__title`]: !showError,
            [`${namespace}__title-error`]: showError,
          })}
        >
          {title}
        </span>
        {showError && IconFactory('MESSAGE_ALERT', colornames('RED'))}
        <span
          className={classNames({
            [`${namespace}__selected`]: !showError,
            [`${namespace}__selected-error`]: showError,
          })}
        >
          {selectedLabel}
          {description}
        </span>
        {!disabled && (
          <span className={`${namespace}__chevron`}>
            <ChevronIcon
              className={classNames('ui-pdp-icon--chevron-down', {
                'ui-pdp-icon--chevron-down--active': active,
                'ui-pdp-icon--chevron-down--error': showError,
                'ui-pdp-icon--disabled': disabled,
              })}
            />
          </span>
        )}
        {message && <span className={`${namespace}__available`}>{message}</span>}
      </Button>
    </div>
  ),
);

QuantityTrigger.propTypes = {
  active: bool.isRequired,
  description: string,
  showError: bool,
  bulkSale: bool,
  message: string,
  selectedLabel: string,
  title: string.isRequired,
  onClick: func,
  disabled: bool,
};

QuantityTrigger.defaultProps = {
  description: null,
  showError: false,
  bulkSale: false,
  selectedLabel: null,
  message: null,
  disabled: false,
  onClick: null,
};

module.exports = QuantityTrigger;
