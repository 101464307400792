import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const CardTooltip = loadable(() => import('../../../../components/card-tooltip'));

const FEATURE_NAME = 'paypal_tooltip';

const mapStateToProps = ({ components: { [FEATURE_NAME]: paypalTooltip } }, ownProps) => ({
  ...paypalTooltip,
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(CardTooltip);
