const React = require('react');
const { string, shape } = require('prop-types');
const { Image } = require('nordic/image');
const StyledLabel = require('../styled-label');
const { trackEvent } = require('../../lib/tracking');
const IconFactory = require('../icons/factory-builder')();

const namespace = 'ui-pdp__header-top-brand';

const HeaderTopBrand = ({ title, logo, url, track, official_store_icon }) => (
  <a href={url} onClick={() => trackEvent(track)}>
    <div className={namespace}>
      {logo && (
        <div className={`${namespace}__image-container`}>
          <Image alt="" className={`${namespace}__image-container__image`} src={logo.url.src} {...logo} />
        </div>
      )}
      <StyledLabel className={`${namespace}__text`} as="p" {...title} />
      {official_store_icon && IconFactory({ id: 'cockade' }, 'ui-pdp-cockade-icon')}
    </div>
  </a>
);

HeaderTopBrand.propTypes = {
  title: shape({
    text: string,
    color: string,
    font_size: string,
  }).isRequired,
  logo: shape({}),
  official_store_icon: string,
  url: string.isRequired,
  track: shape({}),
};

HeaderTopBrand.defaultProps = {
  logo: null,
  title: null,
  url: null,
  track: null,
};

module.exports = HeaderTopBrand;
