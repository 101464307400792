const React = require('react');
const { string, arrayOf, shape } = require('prop-types');

// Highlighted Specs Components
const { Row } = require('../../../grid');
const HSContinousBar = require('../hs-continous-bar');
const HSKeyValue = require('../hs-key-value');
const { generateKeyToComponent } = require('../../../../utils/validators');

const HSAttributes = ({ namespace, dataList }) => (
  <div className={`${namespace}__attribute-columns__column`}>
    {dataList.map(component => (
      <Row key={generateKeyToComponent(component)} className={`${namespace}__attribute-columns__row`}>
        {component.type === 'continuous_bar' ? <HSContinousBar {...component} /> : null}
        {component.type === 'key_value' ? <HSKeyValue {...component} /> : null}
      </Row>
    ))}
  </div>
);

HSAttributes.propTypes = {
  namespace: string,
  dataList: arrayOf(shape()).isRequired,
};

module.exports = HSAttributes;
