const { PROVIDER } = require('../figure/constants');
const jsonToGalleryProps = require('../gallery/json-to-props');

const jsonToProps = enhancedDescriptionProps => {
  const { figures, ...galleryProps } = jsonToGalleryProps({ ...enhancedDescriptionProps });
  return {
    ...galleryProps,
    viewPortTrack: enhancedDescriptionProps.viewport_track,
    maxShowPixels: enhancedDescriptionProps.max_show_pixels,
    pictures: figures.filter(({ provider }) => provider === PROVIDER.IMAGE),
  };
};

module.exports = jsonToProps;
