import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const ShippingMessage = loadable(() => import('../../../../components/shipping-message'));

const FEATURE_NAME = 'no_stock_status';

const mapStateToProps = ({ components: { [FEATURE_NAME]: props } }, ownProps) => ({ ...props, ...ownProps });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { loader: false }),
  connect(mapStateToProps),
);

export default enhance(ShippingMessage);
