const package = require('@vpp-frontend-components/with-box-component');

const {
  ACTION_TYPES: { MODAL, LINK },
  withBoxComponentDesktop: withBoxComponent,
} = package;

module.exports = {
  withBoxComponent,
  MODAL,
  LINK,
};
