import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const FEATURE_NAME = 'header_brand_logo';

const mapStateToProps = ({ components: { [FEATURE_NAME]: props } }, ownProps) => ({
  ...props,
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

const component = Component => enhance(Component);

export default component;
