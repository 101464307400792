import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import jsonToProps from '../../../../components/enhanced-descriptions/json-to-props';

const FEATURE_NAME = 'enhanced_descriptions';

const mapStateToProps = ({ id, components: { [FEATURE_NAME]: enhanced_descriptions } }) => ({
  ...jsonToProps({ ...enhanced_descriptions }),
  id,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

const component = Component => enhance(Component);

export default component;
