import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import actions from '../../../../actions';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const FEATURE_NAME = 'questions';

const mapStateToProps = (
  {
    components: {
      [FEATURE_NAME]: {
        id,
        form,
        question_message,
        quick_access,
        track,
        show_track,
        title,
        messages,
        disabled,
        url,
        stockModal,
        custom_message,
        ...rest
      },
    },
    id: productId,
    attributes,
  },
  ownProps,
) => ({
  id,
  form,
  question_message,
  quick_access,
  title,
  track,
  show_track,
  messages,
  disabled,
  url,
  stockModal,
  custom_message,
  productId,
  attributes,
  ...rest,
  ...ownProps,
});

const mapDispatchToProps = dispatch => ({
  onCreateQuestion: ({ itemId, text, track }) => dispatch(actions.onCreateQuestion({ itemId, text, track })),
  showVariationsError: (deviceType, actionKey) => dispatch(actions.showVariationsError(deviceType, actionKey)),
  showQuestionsAiModal: ({ target, disabled, called_from, source }) =>
    dispatch(actions.showQuestionsAiModal({ target, disabled, called_from, source })),
  postQuestionFromAi: ({ productId, itemId, text, track, attributes, quantity, source }) =>
    dispatch(actions.postQuestionFromAi({ productId, itemId, text, track, attributes, quantity, source })),
  isUseful: ({ itemId, useful, id, response, track, source }) =>
    dispatch(actions.isUseful({ itemId, useful, id, response, track, source })),
  showSnackbar: ({ message, type, delay }) => dispatch(actions.showSnackbar({ message, type, delay })),
  onCreateQuestionFromAi: ({ itemId, text, snackbar_message, suggestionTrack, source }) => {
    dispatch(actions.onCreateQuestionFromAi({ itemId, text, snackbar_message, suggestionTrack, source }));
  },
  saveFrontendStatsd: data => dispatch(actions.saveFrontendStatsd(data)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const component = Component => enhance(Component);

export default component;
export { mapDispatchToProps };
