import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const OtherSellers = loadable(() => import('../../../../components/other-sellers'));

const FEATURE_NAME = 'used_refurbished';
const AVIALABLE_QUANTITY = 'available_quantity';

const mapStateToProps = (
  { components: { [FEATURE_NAME]: other_sellers, [AVIALABLE_QUANTITY]: availableQuantity }, parentUrl },
  ownProps,
) => ({
  ...ownProps,
  ...other_sellers,
  parentUrl,
  selectedQuantity:
    availableQuantity && availableQuantity.state !== 'HIDDEN'
      ? (availableQuantity.picker && availableQuantity.picker.selected) ||
        (availableQuantity.quantity_selector && availableQuantity.quantity_selector.minimum_quantity)
      : 1,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(OtherSellers);
