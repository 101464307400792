import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import jsonToProps from '../../../../components/seller-info/json-to-props';

const FEATURE_NAME = 'seller_info';

const mapStateToProps = ({ components: { [FEATURE_NAME]: sellerInfo } }) => jsonToProps(sellerInfo);

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

const sellerInfo = SellerInfo => enhance(SellerInfo);

export default sellerInfo;
