/* eslint-disable react-hooks/exhaustive-deps */
// Este componente esta siendo migrado al nuevo repositorio de https://github.com/melisource/fury_vpp-frontend-components, no modificar, ante cualquier duda mandar mensaje a Mcalderon

const React = require('react');

const { useEffect, useState } = React;

const useShowLine = (adLoaded, className) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById(className);
      if (element && element.querySelectorAll('iframe').length > 1) {
        setShow(true);
      }
    }, 500);
  }, [adLoaded]);
  return show;
};

module.exports = useShowLine;
