import compose from '../../../../../lib/compose';
import connect from '../../../../../store/connect';
import withFeatureFlag from '../../../../../components/feature-flag/with-feature-flag';
import actions from '../../../../../actions';

const FEATURE_NAME = 'available_quantity';

const mapStateToProps = ({ components: { [FEATURE_NAME]: availableQuantity }, buttonFocus }, ownProps) => ({
  ...availableQuantity,
  ...ownProps,
  buttonFocus,
});

const mapDispatchToProps = dispatch => ({
  onSetQuantity: quantity => dispatch(actions.fetchOnSetQuantity(quantity)),
  onShowInput: show => dispatch(actions.onQuantityShowInput(show)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const component = Component => enhance(Component);

export default component;
