import { loadable } from 'nordic/lazy';
import compose from '../../../../../lib/compose';
import connect from '../../../../../store/connect';
import withFeatureFlag from '../../../../../components/feature-flag/with-feature-flag';
import actions from '../../../../../actions';

const PickUpSummary = loadable(() => import('../../../../../components/buybox/pick-up'));

const FEATURE_NAME = 'pick_up_summary';

const PICK_UP_PROPS = {
  id: FEATURE_NAME,
};

const mapStateToProps = ({ components: { [FEATURE_NAME]: pickUpSummary } }, ownProps) => ({
  ...pickUpSummary,
  ...ownProps,
  ...PICK_UP_PROPS,
});

const serializeOwnProps = (state, ownProps) => ({
  ...ownProps,
  ...PICK_UP_PROPS,
});

const mapDispatchToProps = dispatch => ({
  onShippingPromiseUpdate: () => dispatch(actions.fetchShippingPromise()),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const noFeatureFlagConnect = connect(
  serializeOwnProps,
  mapDispatchToProps,
);

export default enhance(PickUpSummary);
export const noFeatureFlag = noFeatureFlagConnect(PickUpSummary);
