const React = require('react');
const { string, arrayOf } = require('prop-types');
const classnames = require('classnames');
const { generateKeyToComponent } = require('../../utils/validators');

const namespace = 'ui-pdp-features';

const Features = ({ className, features }) => (
  <ul className={classnames(namespace, className)}>
    {features.map(feature => (
      <li key={generateKeyToComponent(feature)} className={`${namespace}__item`}>
        {feature}
      </li>
    ))}
  </ul>
);

Features.propTypes = {
  className: string,
  features: arrayOf(string),
};

Features.defaultProps = {
  className: null,
  features: null,
};

module.exports = React.memo(Features);
