import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import Snackbar from '../../../../components/snackbar-general';
import actions from '../../../../actions';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const FEATURE_NAME = 'snackbar';

const mapStateToProps = ({ components: { [FEATURE_NAME]: snackbarProps } }) => ({ ...snackbarProps });

const mapDispatchToProps = dispatch => ({
  hide: () => {
    dispatch(actions.hideSnackbar());
  },
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(Snackbar);
